<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>
  <p>{{ data }}</p>
  <DxDataGrid
      :key-expr="id"
      :data-source="CustomStoreMain"
      :columns="columns"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"

      ref="dataGrid"
      @exporting="onExporting"
      @initNewRow="initNewRow"
  >
      <DxPaging 
          :enabled="true"
          :page-size="5"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="false"/>

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-deleting="true"
          :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>

      <DxColumn
          data-field="appointment_date"
          caption="Ημερομηνία & ώρα"
          :dataType="'date'"
      
          :format="{  type: 'datetime',
                      formatter: (value) => value.toLocaleString('el-GR', {
                          weekday: 'long', 
                          year: 'numeric', 
                          month: 'long', 
                          day: '2-digit',
                          hour: '2-digit', 
                          minute: '2-digit',
                          second: '2-digit' 
                      })
                    }"
      >
      </DxColumn>

      <DxColumn
          data-field="client_id"
          caption="Πελάτης"
          :hiding-priority="110"
      >
          <DxLookup
              :data-source="CustomStoreLookup_ow_clients" 
              value-expr="id"
              display-expr="name"
          />
      </DxColumn>

      <DxColumn
          data-field="appointment_address"
          caption="Τόποθεσία"
      >
      </DxColumn>

      <DxColumn
          data-field="work_type_id"
          caption="Είδος Εργασίας"
          :hiding-priority="110"
      >
          <DxLookup
              :data-source="CustomStoreLookup_ow_work_types" 
              value-expr="id"
              display-expr="name"
          />
      </DxColumn>

      <DxColumn
          data-field="work_total_price"
          caption="Κόστος Εργασίας"
          data-type="number"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="work_completed_ind"
          caption="Ολοκλήρωση Εργασίας"
          data-type="boolean"
      >
      </DxColumn>

      <DxColumn
          data-field="secretary_comment"
          caption="Σχόλια Τηλεφωνήτριας"
      >
      </DxColumn>

      <DxColumn
          data-field="date_time"
          caption="Επιπλέον Πληροφορίες"
          :visible="false"
      >
      </DxColumn>

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>
</div>
</template>

<script>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      DxLookup,
      //DxRequiredRule,
      //DxToolbar,
      //DxItem
  } from 'devextreme-vue/data-grid'; 

  import 'whatwg-fetch';
  import CustomStore from 'devextreme/data/custom_store';

  import OParameters from '../es-globals';

  //import { exportDataGrid } from 'devextreme/pdf_exporter';

  const URL_SERVICE = OParameters.Host.URI + 'ow_appointments';
  const URL_SERVICE_ow_clients = OParameters.Host.URI + 'ow_clients';
  const URL_SERVICE_ow_work_types = OParameters.Host.URI + 'ow_work_types';  

export default {
          components: {
              DxDataGrid,
              DxColumn,
              DxEditing, 
              DxPaging,
              DxPager,
              DxGroupPanel,
              DxSorting,
              DxFilterRow,
              DxHeaderFilter,
              DxExport,
              DxLookup,
              //DxRequiredRule,
              //DxToolbar,
              //DxItem
      },
      data() {
          return {
        
        formatCurrency: { 
            type: 'currency',
            currency: 'EUR', 
            useGrouping: true, 
            precision: 2 
        },
              CustomStoreMain: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE),
                  insert: (values) => this.sendRequest(URL_SERVICE, 'POST', values),
                  update: (key, values) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
                  remove: (key) => this.sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
                  byKey: (key) => this.sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
              }),
              CustomStoreLookup_ow_clients: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_ow_clients),
                  //byKey: (key) => this.sendRequest(URL_SERVICE_ow_clients + "/" + encodeURIComponent(key)),
              }),
              CustomStoreLookup_ow_work_types: new CustomStore({
                  key: 'id',
                  //loadMode: 'raw',
                  load: () => this.sendRequest(URL_SERVICE_ow_work_types),
                  byKey: (key) => this.sendRequest(URL_SERVICE_ow_work_types + "/" + encodeURIComponent(key)),
              }),

              title: 'Διαχείρηση Ραντεβού',
              pageSizes: [5, 10, 20],
          }
      },
      methods: {
          // 
          //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
          //
          sendRequest(url, method = 'GET', values = {}) {
              // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
              if (method === 'GET') {
                  return fetch(url, {
                      method,
                      credentials: 'include',
                  })
                  .then((response) => response.json()
                  .then((json) => {
                      if (response.ok) return json.records;
                      throw json.Message;
                  }));
              }
              return fetch(url, {
                  method,
                  body: JSON.stringify(values),
                  headers: {
                      'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                  },
                  credentials: 'include',
              }).then((result) => {
                  if (result.ok) {
                      return result.text().then((text) => text && JSON.parse(text));
                  }
                  return result.json().then((json) => {
                      throw json.Message;
                  });
              });
          },
          onExporting (e){
              OParameters.methods.exportDataGridToPdfExcel(e, this.title)
          },
          initNewRow(e) {
              e.data.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ');
          },
      },
  };
</script>
<style lang="scss">
</style>
