<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>

    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :title="title"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                    }"
    >
        <DxScrollView height="100%" width="100%">
            <AppointmentForm
                ref="appointmentForm"
                :appointment_id="selectedAppointmentID" 
                @closePopupEvent="onClosePopupEmit">
            </AppointmentForm>
        </DxScrollView>

    </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="appointment_year"
      :data-source="CustomStoreMain"      
      :allow-column-resizing="false"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      :scrolling="{ mode: 'standard' }"
      
      :selection-filter="['appointments_frequency', '=', '3']"

      @exporting="onExporting"
  >

      <DxPaging 
          :enabled="true"
          :page-size="0"
      />

      <DxFilterRow :visible="false"/>

      <DxHeaderFilter :visible="false"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="false"/>

      <DxSorting mode="single"/>

      <DxEditing
          :allow-updating="false"
          :allow-adding="false"
          :allow-deleting="false"

          :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
            <DxItem location="after">
                <DxDateBox 
                    placeholder="Ημερομηνία"
                    :show-clear-button="true"
                    :use-mask-behavior="true"
                />
            </DxItem>
            
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>

      <DxColumn
          data-field="appointment_year"
          caption="Έτος"
          data-type="number"
      >
      </DxColumn>

      <DxColumn
          data-field="january"
          caption="Ιανουάριος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>
      <DxColumn
          data-field="february"
          caption="Φεβρουάριος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="march"
          caption="Μάρτιος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="april"
          caption="Απρίλιος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="may"
          caption="Μάϊος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="june"
          caption="Ιούνιος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="july"
          caption="Ιούλιος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="august"
          caption="Αύγουστος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="september"
          caption="Σεπτέμβριος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="october"
          caption="Οκτώβριος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="november"
          caption="Νοέμβριος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="december"
          caption="Δεκέμβριος"
          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="total_collected"
          caption="Σύνολο"          
          data-type="number"
          :hiding-priority="-1"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxSummary>

        <DxTotalItem
            column="appointment_year"
            summary-type="sum"
            displayFormat="Σύνολο"
        />

        <DxTotalItem
              column="january"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />

        <DxTotalItem
              column="february"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />

        <DxTotalItem
              column="march"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
        <DxTotalItem
              column="april"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
        <DxTotalItem
              column="may"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
        <DxTotalItem
              column="june"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
        <DxTotalItem
              column="july"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
        <DxTotalItem
              column="august"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
        <DxTotalItem
              column="september"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />

          <DxTotalItem
              column="october"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />

          <DxTotalItem
              column="november"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />
          
          <DxTotalItem
              column="december"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />

          <DxTotalItem
              column="total_collected"
              summary-type="sum"
              displayFormat="{0}"
              :value-format="formatCurrency"
          />


      </DxSummary>

      <!--
      <DxColumn 
            type="buttons">
                <DxButton 
                    text="Επεξεργασία"
                    icon="edit"
                    hint="Επεξεργασία"
                    :on-click="onCustomRowEdit"
                />
          >
      </DxColumn>

      <DxSelection
          mode="multiple"
      />
      -->

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>

</div>

</template>

<script setup>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      //DxLookup, //DxRemoteOperations, //DxRequiredRule,
      DxToolbar,
      DxItem,
      //DxButton,
      DxSummary,
      DxTotalItem
      //DxSelection
  } from 'devextreme-vue/data-grid'

  import 'whatwg-fetch'
  import CustomStore from 'devextreme/data/custom_store'

  //import DxSelectBox from 'devextreme-vue/select-box'

  //import { DxDateRangeBox } from 'devextreme-vue/date-range-box';
  //import DxDateBox from 'devextreme-vue/date-box';  
  
  import DxPopup from 'devextreme-vue/popup'
  import DxScrollView from 'devextreme-vue/scroll-view'
  import AppointmentForm from './ow-appointments-form.vue'
  
  //import DataSource from 'devextreme/data/data_source'

  import OParameters from '../es-globals';

  import { ref, /*reactive, computed, */ onMounted } from 'vue'

  //import { exportDataGrid } from 'devextreme/pdf_exporter';
  const dataGrid = ref(null)
  
  const title = 'Αναφορά Μηνιαίων Εισπράξεων Έτους'
  const pageSizes = [5, 10, 20, 0]
  
  const selectedAppointmentID = ref(null)
  const isPopupVisible = ref(false)
  const formatCurrency = { 
            type: 'currency',
            currency: 'EUR', 
            useGrouping: true, 
            precision: 2 
        }

  const URL_SERVICE = OParameters.Host.URI + 'ow_view_rep_sales_monthly_yearly_summary'
  
  const CustomStoreMain = new CustomStore({
        key: 'appointment_year',

        load: () => sendRequest(URL_SERVICE),
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
            return fetch(url, {
                method,
                credentials: 'include',
            })
            .then(response => {
                return response.json().then(json => {
                    if (response.ok) return json.records;
                    throw json.Message;
                });
            });
        }
        return fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            credentials: 'include',
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => text && JSON.parse(text));
            }
            return result.json().then((json) => {
                throw json.Message;
            });
        });

    }

    const onExporting = (e) => {
        OParameters.methods.exportDataGridToPdfExcel(e, title)
    }

    const onClosePopupEmit = () => {
      //console.log('onClosePopupEmit')
        isPopupVisible.value = false;
        dataGrid.value?.instance?.refresh();
    }

    onMounted(() => {
    })

</script>
<style lang="scss">
</style>
