<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>

    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :columns="columns"
        :column-hiding-enabled="false"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        title="Διαχείριση Ραντεβού"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                    }"
    >
        <DxScrollView height="100%" width="100%">
            <AppointmentForm
                ref="appointmentForm"
                :appointment_id="selectedAppointmentID" 
                @closePopupEvent="onClosePopupEmit">
            </AppointmentForm>
        </DxScrollView>

    </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="id"
      :data-source="CustomStoreMain"
      :columns="columns"
      :allow-column-reordering="false"
      :allow-column-resizing="false"
      :column-hiding-enabled="false"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      :selection-filter="['appointments_frequency', '=', '3']"

      :scrolling="{ mode: 'standard' }" 
      height="auto"

      @exporting="onExporting"
      @selectionChanged="onSelectionChanged"
      @init-new-row="onCustomInitNewRow"
      @initialized="onGridInitialized"
  >
      <DxAdaptive :enabled="true" />

      <DxPaging 
          :enabled="true"
          :page-size="5"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true" :allowHeaderFiltering="false"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="true"

          :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
            <DxItem locate-in-menu="auto" location="before">
                <div class ="ow-list-filters" style="display: flex;">
                    <div class="ow-list-filter">
                    <label class="filter-label" for="selectBoxYear">Έτος: </label>
                    <DxSelectBox   
                        ref="selectBoxYearRef"
                        id="selectBoxYear"
                        :data-source="CustomStoreLookup_ow_view_appointments_years"
                        value-expr="id"
                        display-expr="appointment_year"
                        placeholder="Έτος"
                        width="70"
                        :show-clear-button="false"
                        :search-enabled="false"
                        @initialized="onInitializedYear"
                        @value-changed="onValueChangedYear"
                    />
                </div>

                <div class="ow-list-filter">
                    <label class="filter-label" for="lookupConfirmed">Ολοκλήρωση:</label>
                    <DxLookupEx
                        ref="lookupCompletedRef"
                        id="lookupCompleted"
                        :searchEnabled="false"
                        :data-source="dataSourceCompleted"
                        :value="0"
                        :width="90"
                        :input-attr="{ 'aria-label': 'Grouped lookup' }"
                        value-expr="value"
                        display-expr="title"
                        @initialized="onInitializedCompleted"
                        @value-changed="onValueChangedCompleted"
                    >
                      <DxDropDownOptions
                        :hide-on-outside-click="true"
                        :show-title="false"
                      />
                    </DxLookupEx>
                </div>

                <div class="ow-list-filter">
                    <label class="filter-label" for="lookupConfirmed">Πελάτης:</label>
                    <DxLookupEx
                        ref="lookupClientsRef"
                        id="lookupClients"
                        :searchEnabled="false"
                        :data-source="CustomStoreLookup_ow_clients"
                        :value="1"
                        :width="390"
                        :input-attr="{ 'aria-label': 'Grouped lookup' }"
                        value-expr="id"
                        display-expr="name"
                        search-enabled= "true"
                        sort="name"
                        show-clear-button="true"
                        @initialized="onInitializedClients"
                        @value-changed="onValueChangedClients"
                    >
                      <DxDropDownOptions
                        :hide-on-outside-click="true"
                        :show-title="false"
                      />
                    </DxLookupEx>
                </div>

            </div>
            </DxItem>

            <DxItem location="after">
                <DxDateBox 
                    placeholder="Ημερομηνία"
                    :show-clear-button="true"
                    :use-mask-behavior="true"
                />
            </DxItem>
            <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                onCustomInitNewRow()
                            }
                        }"

                />
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>

      <!--
      <DxColumn
          data-field="app_date"
          caption="Ημερομηνία & ώρα"
          :dataType="'date'"
          :hiding-priority="140"
          width="150"
      
          :format="{  type: 'datetime',
                      formatter: (value) => value.toLocaleString('el-GR', {
                          weekday: 'long', 
                          year: 'numeric', 
                          month: 'long', 
                          day: '2-digit',
                          hour: '2-digit', 
                          minute: '2-digit',
                          second: '2-digit' 
                      })
                    }"
      >
      </DxColumn>
      -->

      <DxGroupPanel :visible="false"/>

      <DxGrouping :auto-expand-all="false"/>

      <DxColumn
          data-field="client_name"
          caption="Πελάτης"
          :hiding-priority="-1"
      >
      </DxColumn>

      <!--
      <DxColumn
          data-field="client_category_id"
          caption="Κατηγορία Πελάτη"
          :hiding-priority="-1"
      >
            <DxLookup
                :data-source="CustomStoreLookup_ow_clients_categories"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>

      <DxColumn
          data-field="clients_sources_id"
          caption="Πηγή Πελάτη"
          :hiding-priority="-1"
      >
            <DxLookup
                :data-source="CustomStoreLookup_ow_clients_sources"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>
      -->

      <DxColumn
          data-field="work_type_id"
          caption="Είδος Εργασίας"
          :hiding-priority="-1"
      >
            <DxLookup
                :data-source="CustomStoreLookup_ow_work_types"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>

      <DxColumn
          data-field="work_completed_ind_bit"
          caption="Ολοκλήρωση"
          data-type="boolean"
          width="100"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="appointment_date"
          caption="Ημερομηνία & Ώρα"
          :dataType="'date'"
          :format="{  type: 'datetime',
                      formatter: (value) => value.toLocaleString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                      })
                    }"
          :hiding-priority="-1"
          sort-order="desc"
      >
      </DxColumn>

      <DxColumn
          data-field="work_total_price"
          caption="Κόστος Εργασίας"
          data-type="number"
          width="100"
          :format="formatCurrency"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="collected_amount"
          caption="Εισπρακτέα"
          data-type="number"
          width="100"
          :format="formatCurrency"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn type="buttons">
              <DxButton 
                text="Επεξεργασία2"
                icon="edit"
                hint="Επεξεργασία"
                :on-click="onCustomRowEdit"
              />

              <DxButton 
                name="delete" 
                hint="Διαγραφή" 
              />
      </DxColumn>

      <DxSummary>
          <DxTotalItem
              column="work_total_price"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="collected_amount"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
      </DxSummary>

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />
  </DxDataGrid>

</div>

</template>

<script setup>
    import {
        DxDataGrid,
        DxColumn,
        DxEditing, 
        DxPaging, 
        DxPager,
        DxGroupPanel,
        DxGrouping,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxExport,
        DxLookup, //DxRemoteOperations, //DxRequiredRule,
        DxToolbar,
        DxItem,
        DxButton,
        DxSummary,
        DxTotalItem,
        DxAdaptive
    } from 'devextreme-vue/data-grid'; 

    import 'whatwg-fetch';
    import CustomStore from 'devextreme/data/custom_store';
    //import { DxDateRangeBox } from 'devextreme-vue/date-range-box';
    //import DxDateBox from 'devextreme-vue/date-box';  
    import DxSelectBox from 'devextreme-vue/select-box'
    import { DxLookup as DxLookupEx, DxDropDownOptions } from 'devextreme-vue/lookup'

    import DxPopup from 'devextreme-vue/popup'
    import DxScrollView from 'devextreme-vue/scroll-view'
    import AppointmentForm from './ow-appointments-form.vue'
    
    import DataSource from 'devextreme/data/data_source'

    import OParameters from '../es-globals';

    import { ref, /*reactive, computed, onMounted */} from 'vue'

    //import { exportDataGrid } from 'devextreme/pdf_exporter';

    const URL_SERVICE = OParameters.Host.URI + 'ow_view_rep_clients_appointments'

    const URL_SERVICE_ow_clients = OParameters.Host.URI + 'ow_clients';  
    const URL_SERVICE_ow_appointments = OParameters.Host.URI + 'ow_appointments'
    //const URL_SERVICE_ow_clients_categories = OParameters.Host.URI + 'ow_clients_categories'
    //const URL_SERVICE_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources'
    const URL_SERVICE_ow_work_types = OParameters.Host.URI + 'ow_work_types'
    const URL_SERVICE_ow_view_appointments_years = OParameters.Host.URI + 'ow_view_appointments_years';  

    const completedIndFilter = [{ id: 1, title: 'Ναι', value: 0, }, { id: 2, title: 'Όχι', value: 1, }, { id: 3, title: 'Ναι & Όχι', value: -1, }]

    const dataGrid = ref(null)
    const appointmentForm = ref(null)

    //const selectBoxClientSourcesRef = ref(null)

    const lookupClientsRef = ref(null)
    const selectedClientID = ref(null)

    const selectBoxYearRef = ref(null)
    const selectedYear = ref(new Date().getFullYear())

    const lookupCompletedRef = ref(null)
    const completedInd = ref(0)

    //const currentYear = ref(new Date().getFullYear()) // Get the current year
    //const currentMonth = ref(new Date().getMonth() + 1) // Get the current month (1-12)
    
    const title = 'Αναφορά Ραντεβού Πελατών ανά Έτος'
    const pageSizes = [5, 10, 20, 0]
    const selectedAppointmentID = ref(null)
    const isPopupVisible = ref(false)

    const formatCurrency = { 
        type: 'currency',
        currency: 'EUR', 
        useGrouping: true, 
        precision: 2 
    }

    const dataSourceCompleted = new DataSource({
        key: 'id',
        store: completedIndFilter,
    })

    const CustomStoreMain = new CustomStore({
        key: 'appointment_id',
        //loadMode: 'raw',
        load: () => {
            const completed = (completedInd.value > -1 ? `&filter=work_completed_ind,neq,${completedInd.value}` : '')
            const client = (selectedClientID.value != null ? `&filter=client_id,eq,${selectedClientID.value}` : '')

            if (selectedClientID.value != null) {
                return sendRequest(URL_SERVICE + '?filter=app_year,eq,' + selectedYear.value + 
                                   completed + client)
            }

        },
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE_ow_appointments + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    
    const CustomStoreLookup_ow_clients = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients + '?filter=active_ind,eq,1'),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients + "/" + encodeURIComponent(key)),
    })

    /*
    const CustomStoreLookup_ow_clients_sources = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients_sources),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients_sources + "/" + encodeURIComponent(key)),
    })
    */

    /*
    const CustomStoreLookup_ow_users = new CustomStore({ 
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_users),
        byKey: (key) => sendRequest(URL_SERVICE_ow_users + "/" + encodeURIComponent(key)),
    })
    
    const CustomStoreLookup_ow_clients_categories = new CustomStore({ 
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients_categories),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients_categories + "/" + encodeURIComponent(key)),
    })
    */
    
    const CustomStoreLookup_ow_work_types = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_work_types),
        byKey: (key) => sendRequest(URL_SERVICE_ow_work_types + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_view_appointments_years = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_appointments_years),
        //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_appointments_years + "/" + encodeURIComponent(key)),
    })
    

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
            return fetch(url, {
                method,
                credentials: 'include',
            })
            .then(response => {
                //this.dataSourceLoaded = true;
                return response.json().then(json => {
                    if (response.ok) return json.records;
                    throw json.Message;
                });
            });
        }
        return fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            credentials: 'include',
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => text && JSON.parse(text));
            }
            return result.json().then((json) => {
                throw json.Message;
            });
        });
    }

    const onExporting = (e) => {
        OParameters.methods.exportDataGridToPdfExcel(e, title)
    }

    const onGridInitialized = () => {
        console.log('onGridInitialized')
        onInitializedYear()
        onInitializedCompleted()
    }

    const onInitializedCompleted = () => {
        lookupCompletedRef.value?.instance?.option('id', completedInd.value);
    }

    const onInitializedYear = () => {
        selectedYear.value = new Date().getFullYear()
        selectBoxYearRef.value?.instance?.option('value', selectedYear.value);
    }

    const onValueChangedYear = (e) => {
        selectedYear.value = e.value
        //console.log('Year change: ' + this.selectedYear)
        dataGrid.value?.instance?.refresh()
        /*
        const dataGrid = this.$refs.dataGrid.instance;
        if (dataGrid) {
            dataGrid.refresh(); // Reload the data grid
        }
        */
    }

    const onValueChangedCompleted = (e) => {
        // console.log(e)
        completedInd.value = e.value
        dataGrid.value?.instance?.refresh()
    }

    const onSelectionChanged = (e) => {
        selectedAppointmentID.value = e.currentSelectedRowKeys[0]
    }

    const onClosePopupEmit = () => {
        isPopupVisible.value = false
        dataGrid.value?.instance?.refresh()
    }

    const onCustomInitNewRow = () => {
        selectedAppointmentID.value = null;
        appointmentForm.value.loadFormData(selectedAppointmentID.value)
        isPopupVisible.value = true;
    }

    const onCustomRowEdit = (e) => {
        console.log(e)
        selectedAppointmentID.value = e.row.key
        isPopupVisible.value = true
    }

    const onInitializedClients = () => {
        lookupClientsRef.value?.instance?.option('value', selectedClientID.value);
    }

    const onValueChangedClients = (e) => {
        selectedClientID.value = e.value
        dataGrid.value?.instance?.refresh()
    }


</script>

<style lang="scss">
</style>
