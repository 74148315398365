<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>
  
  <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        title="Διαχείριση Πελάτη"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                      show: {
                          type: 'pop',
                          duration: 800,
                          from: {
                              scale: 0.90
                          }
                      }, 
                    }"
    >
      <DxScrollView height="100%" width="100%">
          <ClientsForm
              ref="clientsForm"
              :client_id="selectedClientID" 
              @closePopupEvent="onClosePopupEmit">
          </ClientsForm>
      </DxScrollView>

  </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="appointment_id"
      :data-source="CustomStoreMain"
      :columns="columns"
      :allow-column-reordering="false"
      :allow-column-resizing="false"
      :column-hiding-enabled="false"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      @exporting="onExporting"
      @initNewRow="initNewRow"
      :scrolling="{ mode: 'standard' }" 
  >
      <DxPaging 
          :enabled="true"
          :page-size="5"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="false"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="true"/>
      <DxGrouping :auto-expand-all="false"/>

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-updating="false"
          :allow-adding="false"
          :allow-deleting="false"
          :texts= "{
                     addRow: 'Προσθήκη',
                     deleteRow: 'Διαγραφή',
                     editRow:'Επεξεργασία',
                     saveAllChanges:'Αποθήκευση Όλων',
                     cancelAllChanges:'Ακύρωση Όλων'
                   }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
        <DxItem locate-in-menu="auto" location="before">
                <div class ="ow-list-filters" style="display: flex;">
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectDateRangeBox">Ημερομηνία Εργασίας:</label>
                        <DxDateRangeBox
                            ref="selectDateRangeBoxRef"
                            id="selectDateRangeBox"
                            :show-clear-button="true"
                            start-date-label="Από"
                            end-date-label="Εώς"
                            label-mode="static"
                            :multi-view="false"
                            @initialized="onInitializedDateRangeBox"
                            @value-changed="onValueChangedDateRangeBox"
                        />
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="lookupCash">Eξόφληση:</label>
                        <DxLookupEx
                            ref="lookupCashRef"
                            id="lookupCash"
                            :searchEnabled="false"
                            :data-source="dataSourceCash"
                            :value="1"
                            :width="110"
                            :input-attr="{ 'aria-label': 'Grouped lookup' }"
                            value-expr="value"
                            display-expr="title"
                            @initialized="onInitializedCash"
                            @value-changed="onValueChangedCash"
                        >
                        <DxDropDownOptions
                            :hide-on-outside-click="true"
                            :show-title="false"
                        />
                        </DxLookupEx>
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectBoxClientSources">Πηγή Πελάτη:</label>
                        <DxSelectBox
                            ref="selectBoxClientSourcesRef"
                            id="selectBoxClientSources"
                            :data-source="CustomStoreLookup_ow_clients_sources"
                            value-expr="id"
                            display-expr="name"
                            :show-clear-button="true"
                            :search-enabled="false"
                            @initialized="onInitializedClientsSource"
                            @value-changed="onValueChangedClientsSource"
                        />
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectBoxClientType">Τύπος Πελάτη:</label>
                        <DxSelectBox
                            ref="selectBoxClientTypeRef"
                            id="selectBoxClientType"
                            :data-source="CustomStoreLookup_ow_client_types"
                            value-expr="id"
                            display-expr="name"
                            :show-clear-button="true"
                            :search-enabled="false"
                            @initialized="onInitializedClientType"
                            @value-changed="onValueChangedClientType"
                        />
                    </div>
                </div>
            </DxItem>
            <!--
            <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                onCustomInitNewRow()
                            }
                        }"

                />
            -->
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>

      <!--
      <DxColumn
          data-field="active_ind"
          caption="Ενεργός"
          data-type="boolean"
          :hiding-priority="10"
          width="100"
      >
          <DxRequiredRule/>
      </DxColumn>
      -->

      <DxColumn
          data-field="clients_sources_id"
          caption="Πηγή Πελάτη"
          :hiding-priority="20"
          :allow-grouping="true"
          :group-index="1"
      >
            <DxLookup
                :data-source="CustomStoreLookup_ow_clients_sources"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>

      <DxColumn
          data-field="appointment_date"
          caption="Ημερομηνία Εργασίας"
          data-type="date"
          format="dd/MM/yyyy"
      >
      </DxColumn>

      <DxColumn
          data-field="client_name"
          caption="Πελάτης"
          sort-order="asc"
      >
          <DxRequiredRule/>
      </DxColumn>

      <DxColumn
          data-field="avg_work_total_price"
          caption="Κόστος Εργασίας"
          data-type="number"
          :format="OParameters.formatCurrency"
          width="100"
      >
      </DxColumn>

      <DxColumn
          data-field="sum_collected_amount"
          caption="Εισπρακτέα"
          data-type="number"
          :format="OParameters.formatCurrency"
          width="100"
      >
      </DxColumn>

      <!--
      <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
      </DxColumn>
      -->

      <DxSummary>
          <DxTotalItem
              column="avg_work_total_price"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="{0}"
          />
          <DxTotalItem
              column="sum_collected_amount"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="{0}"
          />
          <DxTotalItem
              column="client_name"
              summary-type="count"
              displayFormat="Εργασίες: {0}"
          />
          <DxGroupItem
              column="avg_work_total_price"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="{0}"                
              :align-by-column="true"
          />
          <DxGroupItem
              column="sum_collected_amount"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="{0}"                
              :align-by-column="true"
          />
          <DxGroupItem
              column="client_name"
              summary-type="count"
              displayFormat="Εργασίες: {0}"                
              :align-by-column="true"
          />

      </DxSummary>
      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>
</div>
</template>

<script setup>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      DxLookup, 
      DxRequiredRule,
      //DxButton,
      DxToolbar,
      DxItem,
      DxSummary,
      DxTotalItem,
      DxGroupItem
  } from 'devextreme-vue/data-grid'
  import 'whatwg-fetch'

  import DxSelectBox from 'devextreme-vue/select-box'
  import DxPopup from 'devextreme-vue/popup'
  import DxScrollView from 'devextreme-vue/scroll-view'

  import { DxDateRangeBox } from 'devextreme-vue/date-range-box';

  import { DxLookup as DxLookupEx, DxDropDownOptions } from 'devextreme-vue/lookup'

  import ClientsForm from './ow-clients-form.vue'

  import CustomStore from 'devextreme/data/custom_store'
  import DataSource from 'devextreme/data/data_source'

  import OParameters from '../es-globals'

  import { ref, /*reactive, computed, onMounted */} from 'vue'
  //import { exportDataGrid } from 'devextreme/pdf_exporter'

  const dataGrid = ref(null)
  const clientsForm = ref(null)

  const lookupCashRef = ref(null)
  const selectBoxClientSourcesRef = ref(null)
  const selectBoxClientTypeRef = ref(null)
  const selectDateRangeBoxRef = ref(null)

  const isPopupVisible = ref(false)
  const selectedClientID = ref(null)
  const selectedClientsSourceID = ref(null)
  const selectedClientTypeID = ref(null)
  const selectedDateRange = ref(null)

  const collectedInd = ref(-1)

  const formatCurrency = { 
      type: 'currency',
      currency: 'EUR', 
      useGrouping: true, 
      precision: 2 
  }

  
  const title = 'Αναφορά Εργασιών Ανά Πηγή Πελάτη'
  const pageSizes = [5, 10, 20]

  const URL_SERVICE = OParameters.Host.URI + 'ow_view_rep_client_appointments_analysis'
  const URL_SERVICE_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources'
  const URL_SERVICE_ow_client_types = OParameters.Host.URI + 'ow_client_types'

  const cashFilter = [{ id: 3, title: 'Όλα', value: -1, }, { id: 1, title: 'Επί Πιστώσει', value: 0, }, { id: 2, title: 'Μετρητοίς', value: 1, }]
  
  const dataSourceCash = new DataSource({
        key: 'id',
        store: cashFilter,
  })

  /*
  const LookupCustomDS_ow_appointments_frequency = new DataSource({
    key: 'id',
    store: frequencyFilter,
  })
  */

  const CustomStoreMain = new CustomStore({
            key: 'appointment_id',
            //loadMode: 'raw',
            //load: () => sendRequest(URL_SERVICE),

            load: () => {
                if (selectedDateRange.value != null) {
                  const betweenQuery = (selectedDateRange.value != null ? `&filter=appointment_date,bt,${selectedDateRange.value[0]},${selectedDateRange.value[1]}` : '')

                  const url = `${URL_SERVICE}` + '?filter=clients_sources_id,gt,0' +
                              (selectedClientsSourceID.value != null ? `&filter=clients_sources_id,eq,${selectedClientsSourceID.value}` : '') + 
                              (collectedInd.value > -1 ? `&filter=is_collected_bit,eq,${collectedInd.value}` : '') +
                              (selectedClientTypeID.value != null ? `&filter=client_type_id,eq,${selectedClientTypeID.value}` : '') +
                              betweenQuery
                  //console.log(url)
                  return sendRequest(url)
                }
            },

            insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
        })

    const CustomStoreLookup_ow_clients_sources = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients_sources),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients_sources + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_client_types = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_client_types),
        byKey: (key) => sendRequest(URL_SERVICE_ow_client_types + "/" + encodeURIComponent(key)),
    })

    

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
  const sendRequest = (url, method = 'GET', values = {}) => {
          // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          if (method === 'GET') {
              return fetch(url, {
                  method,
                  credentials: 'include',
              })
              .then((response) => response.json()
              .then((json) => {

                  if (response.ok) return json.records;
                  throw json.Message;
              }));
          }
          return fetch(url, {
              method,
              body: JSON.stringify(values),
              headers: {
                  'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              },
              credentials: 'include',
          }).then((result) => {
              if (result.ok) {
                  return result.text().then((text) => text && JSON.parse(text));
              }
              return result.json().then((json) => {
                  throw json.Message;
              });
          });
        }

        const onExporting = (e) => {
          OParameters.methods.exportDataGridToPdfExcel(e, title)
        }

        const initNewRow = (e) => {
          e.data.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ')
        }

        const onClosePopupEmit = () => {
          isPopupVisible.value = false
          dataGrid.value?.instance?.refresh()
        }

        /*
        const onCustomInitNewRow = (e) => {
            // e.data.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ');
            selectedClientID.value = null
            clientsForm.value.loadFormData(selectedClientID.value)
            isPopupVisible.value = true
        }
        */

        /*
        const onCustomRowEdit = (e) => {
            selectedClientID.value = e.row.key
            isPopupVisible.value = true
        }
        */

        const onInitializedCash = () => {
          console.log('onInitialized')
          console.log(collectedInd.value)
          
          lookupCashRef.value?.instance?.option('value', collectedInd.value);
        }

        const onValueChangedCash = (e) => {
          collectedInd.value = e.value
          dataGrid.value?.instance?.refresh()
        }

        const onInitializedClientsSource = () => {
          selectBoxClientSourcesRef.value?.instance?.option('value', selectedClientsSourceID.value);
        }

        const onValueChangedClientsSource = (e) => {
          selectedClientsSourceID.value = e.value
          dataGrid.value?.instance?.refresh()
        }

        const onInitializedClientType = () => {
          selectBoxClientTypeRef.value?.instance?.option('value', selectedClientTypeID.value);
        }

        const onValueChangedClientType = (e) => {
          selectedClientTypeID.value = e.value
          dataGrid.value?.instance?.refresh()
        }

        const onInitializedDateRangeBox = () => { 
          if (selectedDateRange.value != null) {
            selectDateRangeBoxRef.value?.instance?.option('value', selectedDateRange.value);
          }
        }

        const onValueChangedDateRangeBox = (e) => {
          if (e.value[0] != null && e.value[1] != null) {
            const year1 = e.value[0].getFullYear()
            const month1 = String(e.value[0].getMonth() + 1).padStart(2, '0') // Months are zero-indexed
            const day1 = String(e.value[0].getDate()).padStart(2, '0')
            const year2 = e.value[1].getFullYear()
            const month2 = String(e.value[1].getMonth() + 1).padStart(2, '0') // Months are zero-indexed
            const day2 = String(e.value[1].getDate()).padStart(2, '0')
            selectedDateRange.value = [
              `${year1}-${month1}-${day1}`,
              `${year2}-${month2}-${day2}`
            ]
            dataGrid.value?.instance?.refresh()
          }
        }

</script>
<style lang="scss">
</style>
