<template>
    <div class="grid">

        <div class="content-block dx-card responsive-paddings">
            <h3>Εργασίες Μήνα</h3>

            <DxPieChart
                id="pie"
                :data-source="CustomDS_ow_view_dash_totalappointmentscount"
                palette="carmine"
                palette-extension-mode="blend"
                title = "Πλήθος Εργασιών"
            >
                <DxSeries
                    argument-field="descr"
                    value-field="totalcount"
                >
                    <DxLabel
                        :visible="true"
                        :customize-text="formatLabel"
                    >
                        <DxConnector
                            :visible="true"
                            :width="0.5"
                        />
                    </DxLabel>
                </DxSeries>

                <DxLegendPie :visible="true"
                    :column-count="4"
                    orientation="horizontal"
                    item-text-position="right"
                    horizontal-alignment="center"
                    vertical-alignment="bottom"
                />

                <DxExport :enabled="true"/>

            </DxPieChart>
            
            <DxPieChart
                id="pie"
                :data-source="CustomDS_ow_view_dash_totalappointmentscount"
                palette="carmine"
                palette-extension-mode="blend"
                title = "Αξία Εργασιών"
            >
                <DxSeries
                    argument-field="descr"
                    value-field="totalvalue"
                >
                    <DxLabel
                        :visible="true"
                        :customize-text="formatLabelCurrency"
                    >
                        <DxConnector
                            :visible="true"
                            :width="0.5"
                        />
                        <DxFont :size="12"/>
                    </DxLabel>
                </DxSeries>
                <DxLegendPie
                    :column-count="4"
                    item-text-position="right"
                    horizontal-alignment="center"
                    vertical-alignment="bottom"
                />
                <DxExport :enabled="true"/>
            </DxPieChart>
        </div> 

        <div class="content-block dx-card responsive-paddings">
            <h3>Εργασίες Μήνα ανά Τεχνικό</h3>

        <DxChart
            id="chart"
            :data-source="CustomDS_ow_view_dash_totaltechniciansappointments"
            title=""
            palette="soft"
            palette-extension-mode="blend"
            :rotated="true"
        >
            <DxCommonSeriesSettings
                argument-field="descr"
            />

            <DxArgumentAxis>
                <DxLabel overlapping-behavior="stagger"/>
            </DxArgumentAxis>

            <DxValueAxis 
                :tick-interval="10"
                name="count"
                position="top">
                <DxTitle text="Αριθμός Εργασιών"/>
            </DxValueAxis>

            <DxValueAxis 
                :tick-interval="1"
                name="value"
                position="left">
                <DxLabel
                    :visible="true"
                    :customize-text="formatLabelCurrency"
                >
                </DxLabel>
                <DxTitle text="Αξία Εργασιών"/>
            </DxValueAxis>

            <DxSeries
                name="Αριθμός Εργασιών"
                value-field="totalcount"
                axis="count"
                type="bar"
                :color="red"
            />
            
            <DxSeries
                name="Αξία Εργασιών"
                value-field="totalvalue"
                axis="value"
                type="spline"
                :color="blue"
            />

            <DxLegend
                vertical-alignment="top"
                horizontal-alignment="center"
            />

            <DxExport :enabled="true"/>

            <DxTooltip
                :enabled="true"
                location="edge"
            />

        </DxChart>
        </div>

        <div class="content-block dx-card responsive-paddings">
            <h3>Εισπράξεις Ημέρας ανά Τεχνικό</h3>

        <DxChart
            id="chart"
            :data-source="CustomDS_ow_view_dash_totaltechniciansappointments_daily"
            title=""
            palette="soft"
            palette-extension-mode="blend"
            :rotated="true"
        >
            <DxCommonSeriesSettings
                argument-field="descr"
                type="stackedbar"
            />

            <DxValueAxis>
                <DxTitle text="Αξία"/>
                <DxLabel
                    :visible="true"
                    :customize-text="formatLabelCurrency"
                >
                </DxLabel>
            </DxValueAxis>

            <DxSeries
                name="Αξία ΦΠΑ"
                value-field="totalcount"
            />
            
            <DxSeries
                name="Αξία Είσπραξης"
                value-field="totalvalue"
            />

            <DxLegend
                vertical-alignment="top"
                horizontal-alignment="center"
            />

            <DxExport :enabled="true"/>

            <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                location="edge"
            />

        </DxChart>
        </div>

        <div class="content-block dx-card responsive-paddings">
            <h3>Νέοι Πελάτες Μήνα</h3>
            <DxDataGrid
                :key-expr="id"
                :data-source="CustomDS_ow_view_dash_totalnewclients"
                :toolbar="toolbarOptions"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :row-alternation-enabled="true"
                :word-wrap-enabled="true"
                @exporting="onExporting"
            >
                <DxExport :enabled="true"/>

                <DxTitle text="Λήξη συμβάσεων υπαλλήλων">
                    <DxMargin :bottom="30"/>
                </DxTitle>

                <DxColumn
                    data-field="name"
                    caption="Όνομα"
                >
                </DxColumn>

                <DxColumn
                    data-field="subscription_amount"
                    caption="Κόστος Εργασίας"
                    data-type="number"
                    :format="formatCurrency"
                >
                </DxColumn>

                <DxColumn
                    data-field="totalcount"
                    caption="Πλήθος Εργασιών"
                    data-type="number"
                    sort-order="desc"
                >
                </DxColumn>

            </DxDataGrid>
        </div> 


        
        <div class="content-block dx-card responsive-paddings">
            <h3>Εισπράξεις ανά Πηγή Πελάτη</h3>
            <DxPieChart
                id="pie"
                resolve-label-overlapping="shift"
                :data-source="CustomDS_ow_view_dash_totalclientssourceturnover"
                palette="carmine"
                palette-extension-mode="blend"
                title = "Αξία Εργασιών"
            >
                <DxSeries
                    argument-field="descr"
                    value-field="totalcount"
                >
                    <DxLabel
                        :visible="true"
                        :customize-text="formatLabelCurrency"
                    >
                        <DxConnector
                            :visible="true"
                            :width="0.5"
                        />
                    </DxLabel>
                </DxSeries>

                <DxLegendPie  :visible="true"
                    :column-count="4"
                    orientation="horizontal"
                    item-text-position="right"
                    horizontal-alignment="center"
                    vertical-alignment="bottom"
                />
                <DxExport :enabled="true"/>
            </DxPieChart>
        </div> 

        <div class="content-block dx-card responsive-paddings">
            <h3>Εισπράξεις ανά Τύπο Εργασίας Έτους</h3>
            <DxDataGrid
                :key-expr="work_type_id"
                :data-source="CustomDS_ow_view_dash_totalworktypeturnover"
                :toolbar="toolbarOptions"
                :columns="columns"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :row-alternation-enabled="true"
                :word-wrap-enabled="true"
                @exporting="onExporting"
            >
                <DxExport :enabled="true"/>

                <DxTitle text="Λήξη συμβάσεων υπαλλήλων">
                    <DxMargin :bottom="30"/>
                </DxTitle>

                <DxColumn
                    data-field="descr"
                    caption="Τύπος Εργασίας"
                >
                </DxColumn>

                <DxColumn
                    data-field="totalvalue"
                    caption="Σύνολο"
                    data-type="number"
                    :format="formatCurrency"
                >
                </DxColumn>

                <DxColumn
                    data-field="totalcount"
                    caption="Πλήθος Εργασιών"
                    data-type="number"
                    sort-order="desc"
                >
                </DxColumn>

            </DxDataGrid>
        </div> 
        

        <div class="content-block dx-card responsive-paddings">
            <h3>Πιστώσεις ανά Πηγή Πελάτη</h3>
            <DxPieChart
                id="pie"
                resolve-label-overlapping="shift"
                :data-source="CustomDS_ow_view_dash_totalclientssourcecredits"
                palette="carmine"
                palette-extension-mode="blend"
                title = "Αξία Εργασιών"
            >
                <DxSeries
                    argument-field="descr"
                    value-field="totalcount"
                >
                    <DxLabel
                        :visible="true"
                        :customize-text="formatLabelCurrency"
                    >
                        <DxConnector
                            :visible="true"
                            :width="0.5"
                        />
                    </DxLabel>
                </DxSeries>

                <DxLegendPie  :visible="true"
                    :column-count="4"
                    orientation="horizontal"
                    item-text-position="right"
                    horizontal-alignment="center"
                    vertical-alignment="bottom"
                />
                <DxExport :enabled="true"/>
            </DxPieChart>
        </div> 
 
    </div>
</template>

<script setup>

    const URL_SERVICE_ow_view_dash_totalappointmentscount = OParameters.Host.URI + 'ow_view_dash_totalappointmentscount';
    const URL_SERVICE_ow_view_dash_totalclientssourcecredits = OParameters.Host.URI + 'ow_view_dash_totalclientssourcecredits';
    const URL_SERVICE_ow_view_dash_totalclientssourceturnover = OParameters.Host.URI + 'ow_view_dash_totalclientssourceturnover';
    const URL_SERVICE_ow_view_dash_totalworktypeturnover = OParameters.Host.URI + 'ow_view_dash_totalworktypeturnover';

    
    const URL_SERVICE_ow_view_dash_totalnewclients = OParameters.Host.URI + 'ow_view_dash_totalnewclients';
    const URL_SERVICE_ow_view_dash_totaltechniciansappointments = OParameters.Host.URI + 'ow_view_dash_totaltechniciansappointments';
    const URL_SERVICE_ow_view_dash_totaltechniciansappointments_daily = OParameters.Host.URI + 'ow_view_dash_totaltechniciansappointments_daily';

    /*
    const URL_SERVICE_ow_view_dash_totalclientscredits = OParameters.Host.URI + 'ow_view_dash_totalclientscredits';
    const URL_SERVICE_ow_view_dash_totalclientssatisfaction = OParameters.Host.URI + 'ow_view_dash_totalclientssatisfaction';
    const URL_SERVICE_ow_view_dash_totalclientstypecredits = OParameters.Host.URI + 'ow_view_dash_totalclientstypecredits';
    const URL_SERVICE_ow_view_dash_totaltechnicianscomments = OParameters.Host.URI + 'ow_view_dash_totaltechnicianscomments';
    const URL_SERVICE_ow_view_dash_totalturnover = OParameters.Host.URI + 'ow_view_dash_totalturnover';
    */

    import CustomStore from 'devextreme/data/custom_store'
    import 'whatwg-fetch'

    import DxPieChart, {
        //DxSeries,
        DxLabel,
        DxMargin,
        //DxExport,
        DxLegend as DxLegendPie,
        //DxAnimation,
        DxConnector,
        DxFont,
    } from 'devextreme-vue/pie-chart';
    /*
    import DxFunnel, {
        DxTitle,
        DxMargin,
        DxExport,
        DxTooltip,
        DxItem,
        DxBorder,
        DxLabel
    } from 'devextreme-vue/funnel';
    */
    import {
        DxChart,
        DxSeries,
        DxCommonSeriesSettings,
        DxValueAxis,
        DxTitle,
        DxLegend,
        DxExport,
        DxTooltip,
        DxArgumentAxis
    } from 'devextreme-vue/chart'

    import {DxDataGrid, DxColumn, 
            //DxPaging,
            //DxPager,
            //DxSummary, 
            //DxTotalItem,
            //DxFilterRow,
            //DxHeaderFilter
            /*, DxLookup,*/} from 'devextreme-vue/data-grid'

    import OParameters from '../es-globals'

    //const title = 'Αναφορά Εργασιών Ανά Πηγή Πελάτη'
    //const pageSizes = [5, 10, 20]
    const formatCurrency = { type: 'currency',
                            currency: 'EUR', 
                            useGrouping: true, 
                            precision: 2 
                          }

    const CustomDS_ow_view_dash_totalappointmentscount = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totalappointmentscount /* + "?order=id,desc"*/),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totalappointmentscount + "/" + encodeURIComponent(key)),
            })
    /*
    const CustomDS_ow_view_dash_totalclientscredits = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totalclientscredits),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totalclientscredits + "/" + encodeURIComponent(key)),
            })

    const CustomDS_ow_view_dash_totalclientssatisfaction = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totalclientssatisfaction),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totalclientssatisfaction + "/" + encodeURIComponent(key)),
            })

            const CustomDS_ow_view_dash_totalclientstypecredits = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totalclientstypecredits),
                //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_dash_totalclientssourcecredits + "/" + encodeURIComponent(key)),
            })

    const CustomDS_ow_view_dash_totaltechnicianscomments = new CustomStore({
                    key: 'id',
                    //loadMode: 'raw',
                    //load: () => this.sendRequest(URL_SERVICE_ow_view_dash_totaltechnicianscomments + '?filter=remaining_licenses_count,eq,0'),
                    load: () => sendRequest(URL_SERVICE_ow_view_dash_totaltechnicianscomments),
                    byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totaltechnicianscomments + "/" + encodeURIComponent(key)),
            })

    const CustomDS_ow_view_dash_totalturnover = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                //load: () => this.sendRequest(URL_SERVICE_ow_view_dash_totalturnover + '?filter=quantity,eq,0'),
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totalturnover),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totalturnover + "/" + encodeURIComponent(key)),
            })
    */

    const CustomDS_ow_view_dash_totalclientssourceturnover = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_dash_totalclientssourceturnover),
        //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_dash_totalclientssourceturnover + "/" + encodeURIComponent(key)),
    })

    const CustomDS_ow_view_dash_totalclientssourcecredits = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_dash_totalclientssourcecredits),
        //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_dash_totalclientssourceturnover + "/" + encodeURIComponent(key)),
    })

    const CustomDS_ow_view_dash_totalworktypeturnover = new CustomStore({
        key: 'work_type_id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_dash_totalworktypeturnover),
        //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_dash_totalclientssourceturnover + "/" + encodeURIComponent(key)),
    })

    const CustomDS_ow_view_dash_totaltechniciansappointments = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                //load: () => this.sendRequest(URL_SERVICE_ow_view_dash_totaltechniciansappointments + '?filter=quantity,eq,0'),
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totaltechniciansappointments + "?order=totalvalue,asc"),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totaltechniciansappointments + "/" + encodeURIComponent(key)),
            })

    const CustomDS_ow_view_dash_totaltechniciansappointments_daily = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                //load: () => this.sendRequest(URL_SERVICE_ow_view_dash_totaltechniciansappointments + '?filter=quantity,eq,0'),
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totaltechniciansappointments_daily + "?order=totalvalue,asc"),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totaltechniciansappointments_daily + "/" + encodeURIComponent(key)),
            })

    const CustomDS_ow_view_dash_totalnewclients = new CustomStore({
                key: 'id',
                //loadMode: 'raw',
                //load: () => this.sendRequest(URL_SERVICE_ow_view_dash_totalturnover + '?filter=quantity,eq,0'),
                load: () => sendRequest(URL_SERVICE_ow_view_dash_totalnewclients),
                byKey: (key) => sendRequest(URL_SERVICE_ow_view_dash_totalnewclients + "/" + encodeURIComponent(key)),
            })

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text))
                }
                return result.json().then((json) => {
                    throw json.Message
                })
            });
        }

    const formatLabelCurrency = (pointInfo) => {
        // Convert valueText to a number.
        const formattedValue = parseFloat(pointInfo.value).toFixed(2) + '  €'
        return formattedValue
    }
    
    const formatLabel = (pointInfo) => {
        //return `${pointInfo.valueText} (${pointInfo.percentText})`;
        return `${pointInfo.valueText}`
    }

    const onExporting = (e) => {
        console.log(e)
        //OParameters.methods.exportDataGridToPdfExcel(e, "title")
    }

    const customizeTooltip = ({ valueText }) => ({
        text: `${valueText}${' €'}`,
    });

</script>

<style lang="scss">

#funnel .label {
  font-size: 11px;
}

#funnel .value {
  font-size: 11px;
  border: 1px solid white
}

#chart {
  height: 640px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default column layout */
}

@media (min-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr); /* 2 columns for screens wider than 480px */
  }
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); /* 3 columns for screens wider than 768px */
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr); /* 4 columns for screens wider than 1024px */
  }
}

.dx-card {
    border-radius: 10px !important;
}

</style>
