import OwViewAppointmentsCheck from './views/ow-view-appointments-check';
import OwViewRepClientAppointmentsLate from './views/ow-view-rep-client-appointments-late';
import OwViewRepSalesPeridicalForecast from './views/ow-view-rep-sales-peridical-forecast';
import OwViewRepSalesMonthlyYearlySummary from './views/ow-view-rep-sales-monthly-yearly-summary';
import OwViewRepSalesYearlySummary from './views/ow-view-rep-sales-yearly-summary';
import OwViewRepClientAppointmentsAnalysis from './views/ow-view-rep-client-appointments-analysis';
import OwViewRepClientMonthlySummary from './views/ow-view-rep-client-monthly-summary';
import OwAppointmentsMessages from './views/ow-appointments-messages';
import OwViewRepCreditsInvoices from './views/ow-view-rep-credits-invoices';
import OwViewRepCredits from './views/ow-view-rep-credits';
import OwViewRepClientsAppointments from './views/ow-view-rep-clients-appointments';
import OwClientsForm from './views/ow-clients-form';
import OwAppointmentForm from './views/ow-appointments-form';
import OwViewRepDailyProgramme from './views/ow-view-rep-daily-programme';
import OwViewRepMonthlyProgramme from './views/ow-view-rep-monthly-programme';
import OwAppointments from './views/ow-appointments';
import OwUsers from './views/ow-users';
import OwAppointmentStatus from './views/ow-appointment-status';
import OwClientsSources from './views/ow-clients-sources';
import OwClients from './views/ow-clients';
import OwClientsCategories from './views/ow-clients-categories';
import OwClientTypes from './views/ow-client-types';
import OwWorkTypes from './views/ow-work-types';
import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
//import defaultLayout from "./layouts/side-nav-inner-toolbar";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, 
    {
      path: "/ow-work-types",
      name: "ow-work-types",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwWorkTypes
    }, 
    {
      path: "/ow-client-types",
      name: "ow-client-types",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwClientTypes
    }, 
    {
      path: "/ow-clients-categories",
      name: "ow-clients-categories",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwClientsCategories
    }, 
    {
      path: "/ow-clients-sources",
      name: "ow-clients-sources",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwClientsSources
    }, 
    {
      path: "/ow-appointment-status",
      name: "ow-appointment-status",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwAppointmentStatus
    }, 
    {
      path: "/ow-users",
      name: "ow-users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwUsers
    }, 
    {
      path: "/ow-clients",
      name: "ow-clients",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwClients
    }, 
    {
      path: "/ow-appointments",
      name: "ow-appointments",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwAppointments
    }, 
    {
      path: "/ow-view-rep-monthly-programme",
      name: "ow-view-rep-monthly-programme",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepMonthlyProgramme
    }, 
    {
      path: "/ow-view-rep-daily-programme",
      name: "ow-view-rep-daily-programme",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepDailyProgramme
    }, 
    {
      path: "/ow-appointments-form",
      name: "ow-appointments-form",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwAppointmentForm
    }, 
    {
      path: "/ow-clients-form",
      name: "ow-clients-form",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwClientsForm
    }, 
    {
      path: "/ow-view-rep-clients-appointments",
      name: "ow-view-rep-clients-appointments",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepClientsAppointments
    }, 
    {
      path: "/ow-view-rep-credits",
      name: "ow-view-rep-credits",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepCredits
    }, 
    {
      path: "/ow-view-rep-credits-invoices",
      name: "ow-view-rep-credits-invoices",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepCreditsInvoices
    }, 
    {
      path: "/ow-appointments-messages",
      name: "ow-appointments-messages",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwAppointmentsMessages
    }, 
    {
      path: "/ow-view-rep-client-monthly-summary",
      name: "ow-view-rep-client-monthly-summary",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepClientMonthlySummary
    }, 
    {
      path: "/ow-view-rep-client-appointments-analysis",
      name: "ow-view-rep-client-appointments-analysis",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepClientAppointmentsAnalysis
    }, 
    {
      path: "/ow-view-rep-sales-yearly-summary",
      name: "ow-view-rep-sales-yearly-summary",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepSalesYearlySummary
    }, 
    {
      path: "/ow-view-rep-sales-monthly-yearly-summary",
      name: "ow-view-rep-sales-monthly-yearly-summary",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepSalesMonthlyYearlySummary
    }, 
    {
      path: "/ow-view-rep-sales-peridical-forecast",
      name: "ow-view-rep-sales-peridical-forecast",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepSalesPeridicalForecast
    }, 
    {
      path: "/ow-view-rep-client-appointments-late",
      name: "ow-view-rep-client-appointments-late",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepClientAppointmentsLate
    }, 
    {
      path: "/ow-view-rep-clients-appointments",
      name: "ow-view-rep-clients-appointments",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewRepClientsAppointments
    }, 
    {
      path: "/ow-view-appointments-check",
      name: "ow-view-appointments-check",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: OwViewAppointmentsCheck
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
