<template>
  <div>
      <h3 class="content-block openware-form-title">{{ title }}</h3>
      <SimsDashboardComponent />
  </div>
</template>

<script>

  import SimsDashboardComponent from '../components/es-dashboard-component.vue';

  export default {
      components: {
          SimsDashboardComponent
      },
      data() {
          return {
            title: "Dashboard"
          }
      },
      setup() {
          const picture = "openware/logo-main.png";
          const imageSrc = `/${picture}`;
          return {
              imageSrc,
          };
      }
  }

</script>

<style lang="scss">
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
      display: inline-block;
  }
}

.img-sigma-logo {
  height: 40px;
  margin-bottom: 17px;
}

.vue-logo {
width: 180px;
height: 62px;
}

.plus {
margin: 20px 10px;
width: 22px;
height: 22px;
}

.screen-x-small .logos-container {
svg {
  width: 100%;
  display: block;
  &.plus {
    margin: 0;
  }
}

}
</style>

<style scoped>
.title.content-block {
  text-align: center;
}

</style>