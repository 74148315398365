<template>
  <div>
    <div class="widget-container">
      <DxForm id="form" :form-data="state.formData" label-mode='outside' :read-only='false' :show-colon-after-label='true' label-location='top' :min-col-width='300' :col-count='2' ref="formMaster">

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1" />

        <DxGroupItem caption="Κατάσταση Επιχείρησης" :col-span="3" :col-count="3">
          <DxSimpleItem data-field="active_ind" editor-type="dxCheckBox">
            <DxLabel text="Ενεργή" />
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Επιχείρησης" :col-span="5" :col-count="5">
          <!--
              <DxSimpleItem data-field="id"/>
                <DxSimpleItem
                    data-field="id"
                >
                <DxLabel text="id"/>
              </DxSimpleItem>
          -->

          <DxSimpleItem data-field="name" :is-required="true">
            <DxLabel text="Επωνυμία" />
          </DxSimpleItem>

          <DxSimpleItem data-field="client_category_id" :is-required="true" editor-type="dxLookup" :editor-options="{
                      dataSource: state.LookupCustomDS_ow_clients_categories,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                      dropDownOptions: {
                        hideOnOutsideClick: true,
                        showTitle: false
                      }
                  }">
            <DxLabel text="Κατηγορία Πελάτη" />
          </DxSimpleItem>

          <DxSimpleItem data-field="clients_sources_id" :is-required="true" editor-type="dxLookup" :editor-options="{
                      dataSource: state.LookupCustomDS_ow_clients_sources,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                      dropDownOptions: {
                        hideOnOutsideClick: true,
                        showTitle: false
                      }
                  }">
            <DxLabel text="Πηγή Πελάτη" />
          </DxSimpleItem>

          <DxSimpleItem data-field="tax_no">
            <DxLabel text="Α.Φ.Μ." />
          </DxSimpleItem>

          <DxSimpleItem data-field="tax_office">
            <DxLabel text="Δ.Ο.Υ." />
          </DxSimpleItem>

        </DxGroupItem>
          <DxGroupItem caption="" :col-span="2" :col-count="2">
            
          <DxSimpleItem data-field="comments" editor-type="dxTextArea" :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}">
            <DxLabel :visible="true" text="Σχόλια" />
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Διεύθυνση Επιχείρησης" :col-span="4" :col-count="4">

          <DxSimpleItem data-field="address_street" :is-required="true">
            <DxLabel text="Διεύθυνση Οδός" />
          </DxSimpleItem>

          <DxSimpleItem data-field="address_city" :is-required="true">
            <DxLabel text="Πόλη" />
          </DxSimpleItem>

          <DxSimpleItem data-field="address_postal_code" :is-required="true">
            <DxLabel text="Τ.Κ." />
          </DxSimpleItem>

          <DxSimpleItem data-field="email">
            <DxLabel text="Email" />
          </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Ραντεβού" :col-span="3" :col-count="3">
          <DxSimpleItem data-field="work_type_id" editor-type="dxSelectBox" :is-required="true" :editor-options="{
                        dataSource: state.LookupCustomDS_ow_work_types,
                        searchEnabled: true,
                        displayExpr: 'name',
                        valueExpr:'id',
                        sort: 'name',
                        showClearButton: true,
                      }">
            <DxLabel text="Είδος Εργασίας" />
          </DxSimpleItem>
          
          <DxSimpleItem
              data-field="subscription_amount"
              editor-type="dxNumberBox"
              :is-required="true"
              :editor-options="editorNumberBoxOptions"
          >
              <DxLabel text="Κόστος Εργασίας"/>
          </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem  :col-span="3" :col-count="3">
          <DxSimpleItem data-field="appointments_frequency" 
                        editor-type="dxSelectBox" 
                        :editor-options="{
                            dataSource: OParameters.appointmentsFrequencies,
                            searchEnabled: true,
                            displayExpr: 'name',
                            valueExpr: 'value',
                            sort: 'name',
                            showClearButton: true,
                        }">
            <DxLabel text="Συχνότητα Εργασιών / Έτος" />
          </DxSimpleItem>
          
          <DxSimpleItem 
                  data-field="first_appointment_date"
                  :is-required="true"
                  editor-type="dxDateBox"
                  
                  :editor-options="datetimeBoxEditorOptions"
              >
                <DxLabel text="Ημερομηνία & Ώρα 1ου Ραντεβού"/>
          </DxSimpleItem>

          <DxSimpleItem data-field="recreate_appointments_ind" editor-type="dxCheckBox">
            <DxLabel text="Επαναδημιουργία των Ραντεβού" />
          </DxSimpleItem>

        </DxGroupItem>

        <DxGroupItem caption="Στοιχεία Επικοινωνίας" :col-span="3" :col-count="3">

          <DxSimpleItem data-field="responsible_person_name1">
            <DxLabel text="Ονοματεπώνυμο Υπευθύνου" />
          </DxSimpleItem>

          <DxSimpleItem data-field="mobile_phone_1">
            <DxLabel text="Κινητό 1" />
          </DxSimpleItem>

          <DxSimpleItem data-field="phone_1">
            <DxLabel text="Τηλέφωνο 1" />
          </DxSimpleItem>

          <DxSimpleItem data-field="responsible_person_name2">
            <DxLabel text="Ονοματεπώνυμο Υπευθύνου 2" />
          </DxSimpleItem>

          <DxSimpleItem data-field="mobile_phone_2">
            <DxLabel text="Κινητό 2" />
          </DxSimpleItem>

        </DxGroupItem>
      </DxForm>

      <div id="buttons">
        <DxToolbar>
          <DxItem location="after" widget="dxButton" @click="onClickSave" :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }">
          </DxItem>
          <DxItem location="after" widget="dxButton" @click="onClickClose" :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }">
          </DxItem>
        </DxToolbar>
      </div>
    </div>
  </div>
</template>

<script>

//Form
const URL_SERVICE = OParameters.Host.URI + 'ow_clients';

const URL_SERVICE_LOOKUP_ow_clients_categories = OParameters.Host.URI + 'ow_clients_categories';

const URL_SERVICE_LOOKUP_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources';
const URL_SERVICE_LOOKUP_ow_appointment_status = OParameters.Host.URI + 'ow_appointment_status';

const URL_SERVICE_LOOKUP_ow_work_types = OParameters.Host.URI + 'ow_work_types';
const URL_SERVICE_LOOKUP_ow_view_technicians = OParameters.Host.URI + 'ow_view_technicians';



//SEQUENCE
//const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';

//const DATAGRIDREFKEY = 'my-data-grid';

import CustomStore from 'devextreme/data/custom_store';
//import DataSource from 'devextreme/data/data_source'

import { reactive, onMounted, computed} from 'vue';
import 'whatwg-fetch';

import OParameters from '../es-globals';

import {
    DxForm, 
    DxLabel,
    DxSimpleItem, 
    //DxRequiredRule,
    DxGroupItem,
    DxColCountByScreen,
} from 'devextreme-vue/form'; 

//import DxNumberBox from 'devextreme-vue/number-box';

/*
import {
      DxRequiredRule,
      DxAsyncRule,
} from 'devextreme-vue/validator';
*/
//import DxTextArea from 'devextreme-vue/text-area';
import {/*DxTabPanel*/ DxItem} from 'devextreme-vue/tab-panel';

import DxToolbar from 'devextreme-vue/toolbar';

export default {
    name: 'ClientsForm',
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        //DxRequiredRule,
        DxToolbar,
        DxItem,
        DxColCountByScreen,
        DxGroupItem,        
        //DxTabPanel,
        //DxRequiredRule,
        //DxAsyncRule,
        //DxTextArea,
        //DxNumberBox
        
  },

  data() {
      return {
          helpText: "",
          title: "Διαχείριση Πελάτη",
          calendarOptions: { value: new Date() },
          textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
          tooltipOptions: { enabled: true,
                            position: 'top',
                            contentTemplate: () => {
                              return `
                                <div>
                                  <b>Important:</b>
                                  <p>This is a custom tooltip with HTML.</p>
                                </div>
                              `;
                            }
                          },

          pageSizes: [5, 10, 20],
          OParameters: OParameters,

          //dataGridRefKey: DATAGRIDREFKEY,

          dateBoxEditorOptions: {
            //pickerType: 'rollers',
            type: 'date',
            displayFormat: 'dd/MM/yyyy',
            dateSerializationFormat: 'yyyy-MM-dd',
            invalidDateMessage: 'Λάθος Ημερομηνία',
            showClearButton: true,
            //min: new Date(),
            max: new Date(2030, 11, 31),
          },

          datetimeBoxEditorOptions: {
            //pickerType: 'rollers',
            type: 'datetime',
            pickerType: 'rollers',
            displayFormat: 'dd/MM/yyyy HH:mm',
            dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
            invalidDateMessage: 'Λάθος Ημερομηνία',
            showClearButton: true,
            //min: new Date(),
            max: new Date(2030, 11, 31),
          },

          editorNumberBoxOptions: {
            placeholder: 'Εισάγετε ποσό...',
            format: OParameters.methods.formatCurrencyNumber,
            showSpinButtons: true,
            step: 0.01
          },

          editorPercentBoxOptions: {
            placeholder: 'Εισάγετε ποσό...',
            format: OParameters.methods.formatPercentNumber,
            showSpinButtons: true,
            step: 0.01
          },
      }
  },

  props: {
    client_id: {
        type: Number,
        required: true
    }
  },

  watch: {
    client_id(newValue, oldValue) {
        this.$emit('client-id-value-changed', newValue, oldValue);
        this.loadFormData(newValue);
    }
  },

  setup(props) {
    const gridWidth = computed(() => '100%'); // set the width to 100%
    const maxWidth = computed(() => '800px'); // set the max-width to 800px
    const state = reactive({
        formData: {},

        /*
        LookupCustomDS_ow_appointments_frequency: new DataSource({
                  key: 'id',
                  store: frequencyFilter,
        }),
        */

        LookupCustomDS_ow_clients_categories: new CustomStore({
              key: 'id', 
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_clients_categories + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_clients_categories + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_clients_sources: new CustomStore({
              key: 'id', 
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_clients_sources + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_clients_sources + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_appointment_status: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_appointment_status + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_appointment_status + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_work_types: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_work_types + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_work_types + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_view_technicians: new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_view_technicians + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_view_technicians + "/" + encodeURIComponent(key)),
        }),

        CustomStoreMain: new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE),
            insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
        }),
    });

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
              return fetch(url, {
                  method,
                  credentials: 'include',
              })
              .then(response => response.json())
              .then(json => {
                  return json.records;
              })
          }
          return fetch(url, {
              method,
              body: JSON.stringify(values),
              headers: {
                  'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              },
              credentials: 'include',
          }).then((result) => {
              if (result.ok) {
                  return result.text().then((text) => text && JSON.parse(text));
              }
              return result.json().then((json) => {
                  throw json.Message;
              });
          });
    };

    const sendRequestByKey = (url, method = 'GET') => {
        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        return fetch(url, {
                  method,
                  headers: {
                      'Accept': 'application/json',
                  },
                  credentials: 'include',
              })
              .then(response => response.json())
    };

    const saveData = async () => {
        // console.log('Save data');
          return await state.CustomStoreMain.update(state.formData.id, state.formData);
    };

    const insertData = async () => {
        // console.log('Insert data');
         return await state.CustomStoreMain.insert(state.formData);
    };

    const clearForm = () => {
          state.formData = {};
    };

    var isGridValidated = true
    var initialCodeValue
    var isInsertMode = false

    onMounted(async () => {
        if (props.client_id != null) this.state.formData = await this.state.CustomStoreMain.byKey(props.client_id)
    });

    return {
        saveData,
        insertData,
        clearForm,
        gridWidth,
        maxWidth,
        state,
        isGridValidated,
        initialCodeValue,
        isInsertMode
    };
  },
  methods: {
      
      async loadFormData(id) {
          //
          // nf 28/6/2023 
          // init disposal value in order to invoke onchange event which disables dispose field
          //
          // this.$refs.formMaster.instance.getEditor('device_disposal_ind').option('value', true)


          this.state.formData = {}
          if (id != null) {
              this.isInsertMode = false
              this.state.formData = await this.state.CustomStoreMain.byKey(id)
          } else {
              this.isInsertMode = true
              this.state.formData.active_ind = 1
              this.state.formData.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ')
          }

          //this.initialCodeValue = this.state.formData.code
      },

      onClickSave: async function() {
          const formValidator = this.dataForm.validate()
          this.isGridValidated = true
          let formSaved = false

          if (formValidator.isValid) {
              if (!this.isInsertMode) {
                  await this.saveData()
                  formSaved = true
              } else {
                  await this.insertData()
                  formSaved = true
              }
          }

          if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

      },
      onClickClose: function() {
          this.$emit('closePopupEvent', true)
      },

      onInitNewRow(row) {
          console.log('row')
          console.log(row)
          //row.data.hw_pc_desktop_device_id = this.state.formData.id
      },

      onRowValidating(e) {
          this.isGridValidated = this.isGridValidated && e.isValid
      },



  },
  computed: {    
      dataForm: function() {
          return this.$refs.formMaster.instance
      },
  }
};
</script>

<style lang="scss">

/* Target the header titles in the DxDataGrid */
.dx-datagrid-headers .dx-datagrid-text-content {
  font-weight: bold;
}

/* Target the labels in the DxForm */
.dx-field-item-label-text {
  font-weight: bold;
}

.ow-list-filters {
    display: flex;
    background: #ffffff;
    padding: 8px 8px 0 8px;
    flex-wrap: wrap;
    gap: 10px; /* Adjust as needed for spacing between items */
}

.dx-toolbar-label .dx-toolbar-item-content > div {
    width: 200px;
}

.filter-label {
    font-weight: bold;
    align-content: center;
    padding: 0 5px 0 5px;
}

.ow-list-filter {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Space between items when stacked */
  
  font-size: 12px;
}

.filter-label {
  margin-right: 5px; /* Space between label and input */
}

</style>

<style scoped>


#form {
 margin-top: 25px;
}
.caption {
  font-size: 18px;
  font-weight: 500;
}
.option {
  margin-top: 10px;
}
.dx-fieldset-header {
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 4px;
}

</style>
