<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>

    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :title="title"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                    }"
    >
        <DxScrollView height="100%" width="100%">
            <AppointmentForm
                ref="appointmentForm"
                :appointment_id="selectedAppointmentID" 
                @closePopupEvent="onClosePopupEmit">
            </AppointmentForm>
        </DxScrollView>

    </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="id"
      :data-source="CustomStoreMain"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      :scrolling="{ mode: 'standard' }"
      
      :selection-filter="['appointments_frequency', '=', '3']"

      @exporting="onExporting"
      @cell-prepared="onCellPrepared"
  >

      <DxPaging 
          :enabled="true"
          :page-size="5"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="false"/>

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-updating="false"
          :allow-adding="false"
          :allow-deleting="false"

          :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
        <DxItem locate-in-menu="auto" location="before">
            <div class ="ow-list-filters" style="display: flex;">
                <div class="ow-list-filter">
                    <label class="filter-label" for="selectBoxYear">Έτος: </label>
                    <DxSelectBox   
                        ref="selectBoxYearRef"
                        id="selectBoxYear"
                        :data-source="CustomStoreLookup_ow_view_appointments_years"
                        value-expr="id"
                        display-expr="appointment_year"
                        placeholder="Έτος"
                        width="70"
                        :show-clear-button="false"
                        :search-enabled="false"
                        @initialized="onInitializedYear"
                        @value-changed="onValueChangedYear"
                    />
                </div>

                <div class="ow-list-filter">
                    <label class="filter-label" for="selectBoxYear">Πηγή Πελάτη:</label>
                    <DxSelectBox
                        ref="selectBoxClientSourcesRef"
                        id="selectBoxClientSources"
                        :data-source="CustomStoreLookup_ow_clients_sources"
                        value-expr="id"
                        display-expr="name"
                        :show-clear-button="true"
                        :search-enabled="false"
                        @initialized="onInitializedClientsSource"
                        @value-changed="onValueChangedClientsSource"
                    />
                </div>
            </div>
        </DxItem>

            
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>

      <DxColumn
          data-field="client_id"
          caption="Πελάτης"
          :hiding-priority="-1"
      >
          <DxLookup
              :data-source="CustomStoreLookup_ow_clients" 
              value-expr="id"
              display-expr="name"
          />
      </DxColumn>

      <!--
      <DxColumn
          data-field="appointment_date"
          caption="Ημερομηνία & ώρα"
          :dataType="'date'"
          sort-order="desc"
      
          :format="{  type: 'datetime',
                      formatter: (value) => value.toLocaleString('el-GR', {
                          weekday: 'long', 
                          year: 'numeric', 
                          month: 'long', 
                          day: '2-digit',
                          hour: '2-digit', 
                          minute: '2-digit',
                          second: '2-digit' 
                      })
                    }"
      >
      </DxColumn>

      <DxColumn
          data-field="client_address"
          caption="Διεύθυνση"
      >
      </DxColumn>

      <DxColumn
          data-field="invoice_no"
          caption="Αρ. Παραστατικού"
      >
      </DxColumn>
      -->


        <DxColumn caption="Αριθμός Ραντεβού (Σύνολο Έτους)">

            <DxColumn
                data-field="appointments_frequency"
                caption="Συχνότητα"
                data-type="number"
                :hiding-priority="-1"
                
            >
            </DxColumn>
            <DxColumn
                data-field="appointments_count"
                caption="Προγραμματιασμένα"
                data-type="number"
                :hiding-priority="-1"
                
            >
            </DxColumn>

            <DxColumn
                data-field="count_gt_appointments_frequency"
                caption="Διαφορά"
                :hiding-priority="-1"
                width="100"
                :calculate-cell-value="calculateCount_gt_appointments_frequencyCellValue"
            >
            </DxColumn>
        </DxColumn>

        <DxColumn caption="Αριθμός Ραντεβού (Έως Σήμερα)">
            <DxColumn
                data-field="confirmed_appointments_count_uptoday"
                caption="Επιβεβαιωμένα"
                data-type="number"
                :hiding-priority="-1"
                
            >
            </DxColumn>

            <DxColumn
                data-field="completed_appointments_count_uptoday"
                caption="Ολοκληρωμένα"
                data-type="number"
                :hiding-priority="-1"
                
            >
            </DxColumn>
            

            <DxColumn
                data-field="count_gt_appointments_count_uptoday"
                caption="Διαφορά"
                :hiding-priority="-1"
                width="100"
                :calculate-cell-value="calculateCount_gt_appointments_count_uptodayCellValue"
            >
            </DxColumn>
        </DxColumn>

      <DxSummary>
          <DxTotalItem
              column="appointments_count"
              summary-type="sum"
              displayFormat="{0}"
          />
          <DxTotalItem
              column="appointments_frequency"
              summary-type="sum"
              displayFormat="{0}"
          />
      </DxSummary>

      <!--
      <DxColumn 
            type="buttons">
                <DxButton 
                    text="Επεξεργασία"
                    icon="edit"
                    hint="Επεξεργασία"
                    :on-click="onCustomRowEdit"
                />
          >
      </DxColumn>

      <DxSelection
          mode="multiple"
      />
      -->

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>

</div>

</template>

<script setup>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      DxLookup, //DxRemoteOperations, //DxRequiredRule,
      DxToolbar,
      DxItem,
      //DxButton,
      DxSummary,
      DxTotalItem,
      //DxSelection
  } from 'devextreme-vue/data-grid'

  import 'whatwg-fetch'
  import CustomStore from 'devextreme/data/custom_store'

  import DxSelectBox from 'devextreme-vue/select-box'

  //import { DxDateRangeBox } from 'devextreme-vue/date-range-box';
  //import DxDateBox from 'devextreme-vue/date-box';  
  
  import DxPopup from 'devextreme-vue/popup'
  import DxScrollView from 'devextreme-vue/scroll-view'
  import AppointmentForm from './ow-appointments-form.vue'
  
  //import DataSource from 'devextreme/data/data_source'

  import OParameters from '../es-globals';

  import { ref, /*reactive, computed, */ onMounted } from 'vue'

  //import { exportDataGrid } from 'devextreme/pdf_exporter';
  const dataGrid = ref(null)
  
  const selectBoxYearRef = ref(null)
  const selectedYear = ref(new Date().getFullYear())

  const title = 'Έλεγχος Ραντεβού'
  const pageSizes = [5, 10, 20, 100, 1000]
  
  const selectedAppointmentID = ref(null)
  const isPopupVisible = ref(false)

  const selectedClientsSourceID = ref(null)
  const selectBoxClientSourcesRef = ref(null)


  /*
  const formatCurrency = { 
            type: 'currency',
            currency: 'EUR', 
            useGrouping: true, 
            precision: 2 
        }
  */

  const URL_SERVICE = OParameters.Host.URI + 'ow_view_appointments_check'
  const URL_SERVICE_ow_clients = OParameters.Host.URI + 'ow_clients'
  const URL_SERVICE_ow_view_appointments_years = OParameters.Host.URI + 'ow_view_appointments_years'
  const URL_SERVICE_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources';

  //const URL_SERVICE_ow_view_appointments_months_years = OParameters.Host.URI + 'ow_view_appointments_months_years'

  // const URL_SERVICE_ow_users = OParameters.Host.URI + 'ow_users'
  
  // const URL_SERVICE_ow_appointments = OParameters.Host.URI + 'ow_appointments';
  
    const CustomStoreMain = new CustomStore({
        key: 'client_id',
        //loadMode: 'raw',
        //load: () => this.sendRequest(URL_SERVICE + '?filter=confirmed_ind,eq,' + this.confirmedInd + '&filter=app_year,eq,' + this.selectedYear + '&filter=app_month,eq,2100-' + this.selectedMonth.toString().padStart(2, '0') + '-01'),
        load: () => sendRequest(URL_SERVICE + '?filter=appointments_year,eq,' + selectedYear.value+
        (selectedClientsSourceID.value != null ? `&filter=clients_sources_id,eq,${selectedClientsSourceID.value}` : '')),
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_view_appointments_years = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_appointments_years),
        //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_appointments_years + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_clients = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_clients_sources = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients_sources),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients_sources + "/" + encodeURIComponent(key)),
    })

    /*


    const CustomStoreLookup_ow_view_appointments_months_years = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_appointments_months_years),
        //byKey: (key) => this.sendRequest(URL_SERVICE_ow_view_appointments_months_years + "/" + encodeURIComponent(key)),
    })

    */

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
            return fetch(url, {
                method,
                credentials: 'include',
            })
            .then(response => {
                return response.json().then(json => {
                    if (response.ok) return json.records;
                    throw json.Message;
                });
            });
        }
        return fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            credentials: 'include',
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => text && JSON.parse(text));
            }
            return result.json().then((json) => {
                throw json.Message;
            });
        });

    }

    const onExporting = (e) => {
        OParameters.methods.exportDataGridToPdfExcel(e, title)
    }

    const onClosePopupEmit = () => {
        console.log('onClosePopupEmit')
        isPopupVisible.value = false;
        dataGrid.value?.instance?.refresh();
    }

    /*
    const onCustomRowEdit = (e) => {
        console.log('onCustomRowEdit')
        selectedAppointmentID.value = e.row.key
        isPopupVisible.value = true
    }
    */

    const onInitializedYear = () => {
        selectedYear.value = new Date().getFullYear()
        selectBoxYearRef.value?.instance?.option('value', selectedYear.value);
    }

    const onValueChangedYear = (e) => {
        selectedYear.value = e.value
        //console.log('Year change: ' + this.selectedYear)
        dataGrid.value?.instance?.refresh()
        /*
        const dataGrid = this.$refs.dataGrid.instance;
        if (dataGrid) {
            dataGrid.refresh(); // Reload the data grid
        }
        */
    }

    function calculateCount_gt_appointments_frequencyCellValue(data) {
        //console.log(data)
        
        if (data.appointments_frequency == null) {
        return 'Χωρίς Συχνότητα'
        } else {
            return data.appointments_count - data.appointments_frequency
        }
    }


    function calculateCount_gt_appointments_count_uptodayCellValue(data) {
        return data.completed_appointments_count_uptoday - data.confirmed_appointments_count_uptoday
    }

    function onCellPrepared(e) {
        if (e.rowType === "data") {
            if (e.column.dataField === "count_gt_appointments_frequency") {
              //console.log('e.data.sum_collected_amount > e.data.sum_collected_amount_last_year', e.data.sum_collected_amount, e.data.sum_collected_amount_last_year)
              if (parseFloat(e.data.appointments_count) >= parseFloat(e.data.appointments_frequency)) {
                console.log('green')
                e.cellElement.style.cssText = "color: white; background-color: green; text-align: right;";
              } else {
                console.log('red')
                e.cellElement.style.cssText = "color: white; background-color: red; text-align: right;";
              }
            }
            if (e.column.dataField === "count_gt_appointments_count_uptoday") {
              //console.log('e.data.sum_collected_amount > e.data.sum_collected_amount_last_year', e.data.sum_collected_amount, e.data.sum_collected_amount_last_year)
              if (parseFloat(e.data.completed_appointments_count_uptoday) >= parseFloat(e.data.confirmed_appointments_count_uptoday)) {
                console.log('green')
                e.cellElement.style.cssText = "color: white; background-color: green; text-align: right;";
              } else {
                console.log('red')
                e.cellElement.style.cssText = "color: white; background-color: red; text-align: right;";
              }
            }
        }
    }



    const onInitializedClientsSource = () => {
            selectBoxClientSourcesRef.value?.instance?.option('value', selectedClientsSourceID.value);
        }

    const onValueChangedClientsSource = (e) => {
        selectedClientsSourceID.value = e.value
        dataGrid.value?.instance?.refresh()
    }
        // Lifecycle hooks
    onMounted(() => {
    })

</script>
<style lang="scss">
td[role=columnheader] {  
    text-align: center!important  
}  
</style>
