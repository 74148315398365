<template>
  <div>    
      <div class="widget-container">
          <DxForm
            id="form"
            :form-data="state.formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            ref="formMaster"
          >

          <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

          <DxGroupItem caption="Βασικά Στοιχεία" :col-span="3" :col-count="3">
              <!--
              <DxSimpleItem data-field="id"/>

              <DxSimpleItem
                  data-field="id"
              >
                  <DxLabel text="id"/>
              </DxSimpleItem>
              -->

              <DxSimpleItem
                  data-field="locked_ind"
                  editor-type="dxCheckBox"
              >
                  <DxLabel text="Κλειδωμένο"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="confirmed_ind"
                  editor-type="dxCheckBox"
              >
                <DxLabel text="Επιβεβαιωμένο"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="work_completed_ind"
                  editor-type="dxCheckBox"
              >
                  <DxLabel text="Ολοκλήρωση Εργασίας"/>
              </DxSimpleItem>

              <DxSimpleItem 
                  data-field="appointment_date"
                  :is-required="true"
                  :editor-options="datetimeBoxEditorOptions"
                  editor-type="dxDateBox"
              >
                <DxLabel text="Ημερομηνία & Ώρα Ραντεβού"/>
              </DxSimpleItem>
              
              <DxSimpleItem
                  data-field="recreate_appointments_ind"
                  editor-type="dxCheckBox"
              >
                  <DxLabel text="Επαναδημιουργία των Ραντεβού"/>
              </DxSimpleItem>

              <!--
              <DxSimpleItem
                  data-field="client_id"
                  :is-required="true"
                  editor-type="dxSelectBox"
                  :editor-options="{
                      dataSource: state.LookupCustomDS_ow_clients,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                  }"
              >
                  <DxLabel text="Πελάτης"/>
              </DxSimpleItem>
              -->
              <DxSimpleItem
                  data-field="client_id"
                  :is-required="true"
                  editor-type="dxLookup"
                  :editor-options="{
                      dataSource: state.LookupCustomDS_ow_clients,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                      dropDownOptions: {
                        hideOnOutsideClick: true,
                        showTitle: false
                      },                      
                      onValueChanged: this.onClientIDChange
                  }"
              >
                  <DxLabel text="Πελάτης"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="appointment_address"
                  :is-required="true"
              >
                  <DxLabel text="Τόπος Εργασίας"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="work_type_id"
                  editor-type="dxSelectBox"
                  :is-required="true"
                  :editor-options="{
                      dataSource: state.LookupCustomDS_ow_work_types,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                  }"
              >
                  <DxLabel text="Είδος Εργασίας"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="technician"
                  editor-type="dxSelectBox"
                  searchEnabled = true
                  :editor-options="{
                      dataSource: state.LookupCustomDS_ow_view_technicians,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                  }"
              >
                  <DxLabel text="Τεχνικός"/>
              </DxSimpleItem>

              </DxGroupItem>

              <DxGroupItem caption="Οικονομικά Στοιχεία" :col-span="8" :col-count="8">

              <DxSimpleItem
                  data-field="work_total_price"
                  editor-type="dxNumberBox"
                  :is-required="true"
                  :editor-options="editorNumberBoxOptions"
              >
              
                  <DxLabel text="Κόστος Εργασίας"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="work_total_price_vat_ind"
                  editor-type="dxCheckBox"
              >
                  <DxLabel text="Κόστος Εργασίας με ΦΠΑ"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="collected_amount"
                  editor-type="dxNumberBox"
                  :editor-options="editorNumberBoxOptions"
              >
                  <DxLabel text="Ποσό Είσπραξης"/>
              </DxSimpleItem>
            </DxGroupItem>

            <DxGroupItem caption="Στοιχεία Παραστατικού" :col-span="8" :col-count="8">

              <DxSimpleItem
                  data-field="invoice_issued_ind"
                  editor-type="dxCheckBox"
              >
                  <DxLabel text="Έχει Εκδοθεί Τιμολόγιο"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="invoice_no"
              >
                  <DxLabel text="Αρ. Τιμολογίου"/>
              </DxSimpleItem>


              <DxSimpleItem
                  data-field="vat_percent"
                  editor-type="dxNumberBox"
                  :editor-options="editorPercentBoxOptions"
              >
                  <DxLabel text="Ποσοστό Φ.Π.Α."/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="vat_value"
                  editor-type="dxNumberBox"
                  :editor-options="editorNumberBoxOptions"
              >
                  <DxLabel text="Ποσό Φ.Π.Α."/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="total_value"
                  editor-type="dxNumberBox"
                  :editor-options="editorNumberBoxOptions"
              >
                  <DxLabel text="Σύνολο"/>
              </DxSimpleItem>

              <!--
              <DxSimpleItem
                    data-field="secretary_comment"
                    editor-type="dxTextArea"
                    :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
                >
                  <DxTextArea/>
                  <DxLabel :visible="true" text="Σχόλια Τηλεφωνήτριας"/>
              </DxSimpleItem>

              <DxSimpleItem
                  data-field="appointment_status_id"
                  editor-type="dxSelectBox"
                  :editor-options="{
                      dataSource: state.LookupCustomDS_ow_appointment_status,
                      searchEnabled: true,
                      displayExpr: 'name',
                      valueExpr:'id',
                      sort: 'name',
                      showClearButton: true,
                  }"
              >
                  <DxLabel text="Προτεραιότητα"/>
              </DxSimpleItem>
              -->

              </DxGroupItem>

              <DxGroupItem caption="Σχόλια">
                <DxSimpleItem
                    data-field="admin_comment"
                    editor-type="dxTextArea"
                    :editor-options="{placeholder: 'Προσθήκη σχόλιου...', height: 75, rows: 10}"
                >
                    <DxLabel :visible="true" text="Μήνυμα"/>
                </DxSimpleItem>

                <DxItem 
                    data-field="technician_comment"
                >
                    <DxTextArea
                        height= 75
                        rows= 10
                        placeholder= "Προσθήκη σχόλιου..."
                        v-model:value="state.formData.technician_comment"
                    />
                    <DxLabel text="Σχόλιο Τεχνικού" />
                </DxItem>

              </DxGroupItem>
          </DxForm>

      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
      </div>
      </div>
  </div>
</template>

<script>

//Form
const URL_SERVICE = OParameters.Host.URI + 'ow_appointments';
const URL_SERVICE_LOOKUP_ow_clients = OParameters.Host.URI + 'ow_clients';
const URL_SERVICE_LOOKUP_ow_appointment_status = OParameters.Host.URI + 'ow_appointment_status';
const URL_SERVICE_LOOKUP_ow_work_types = OParameters.Host.URI + 'ow_work_types';
const URL_SERVICE_LOOKUP_ow_view_technicians = OParameters.Host.URI + 'ow_view_technicians';

//SEQUENCE
//const URL_SERVICE_seq_hw_nextval_VIEW = OParameters.Host.URI + 'seq_hw_nextval_VIEW';
//const DATAGRIDREFKEY = 'my-data-grid';

import CustomStore from 'devextreme/data/custom_store';
import { reactive, onMounted, computed} from 'vue';
import 'whatwg-fetch';
import OParameters from '../es-globals';
import {
    DxForm, 
    DxLabel,
    DxSimpleItem, 
    //DxRequiredRule,
    DxGroupItem,
    DxColCountByScreen,
} from 'devextreme-vue/form'; 

//import DxNumberBox from 'devextreme-vue/number-box';

/*
import {
      DxRequiredRule,
      DxAsyncRule,
} from 'devextreme-vue/validator';
*/

import { DxTextArea } from 'devextreme-vue/text-area';
import {/*DxTabPanel*/ DxItem} from 'devextreme-vue/tab-panel';
import DxToolbar from 'devextreme-vue/toolbar';

export default {
    name: 'AppointmentForm',
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        //DxRequiredRule,
        DxToolbar,
        DxItem,
        DxColCountByScreen,
        DxGroupItem,
        //DxTabPanel,
        //DxRequiredRule,
        //DxAsyncRule,
        DxTextArea,
        //DxNumberBox
  },

  data() {
      return {
          helpText: "",
          title: "Διαχείριση Ραντεβού",
          calendarOptions: { value: new Date() },
          textAreaOptions: { placeholder: 'Προσθήκη σχολίου..'},
          pageSizes: [5, 10, 20],
          //dataGridRefKey: DATAGRIDREFKEY,

          dateBoxEditorOptions: {
            //pickerType: 'rollers',
            type: 'date',
            displayFormat: 'dd/MM/yyyy',
            dateSerializationFormat: 'yyyy-MM-dd',
            invalidDateMessage: 'Λάθος Ημερομηνία',
            showClearButton: true,
            //min: new Date(),
            max: new Date(2030, 11, 31),
          },

          datetimeBoxEditorOptions: {
            //pickerType: 'rollers',
            type: 'datetime',
            pickerType: 'rollers',
            displayFormat: 'dd/MM/yyyy HH:mm',
            dateSerializationFormat: 'yyyy-MM-dd HH:mm:ss',
            invalidDateMessage: 'Λάθος Ημερομηνία',
            showClearButton: true,
            //min: new Date(),
            max: new Date(2030, 11, 31),
          },

          editorNumberBoxOptions: {
            placeholder: 'Εισάγετε ποσό...',
            format: OParameters.methods.formatCurrencyNumber,
            showSpinButtons: true,
            step: 0.01
          },

          editorPercentBoxOptions: {
            placeholder: 'Εισάγετε ποσό...',
            format: OParameters.methods.formatPercentNumber,
            showSpinButtons: true,
            step: 0.01
          },
      }
  },

  props: {
    appointment_id: {
        type: Number,
        required: true
    }
  },

  watch: {
    appointment_id(newValue, oldValue) {
        //console.log('watch appointment_id')
        this.$emit('appointment-id-value-changed', newValue, oldValue)
        this.loadFormData(newValue)
    }
  },

  setup(/*props*/) {
    const gridWidth = computed(() => '100%'); // set the width to 100%
    const maxWidth = computed(() => '800px'); // set the max-width to 800px
    const state = reactive({
        formData: {},

        LookupCustomDS_ow_clients:  new CustomStore({
              key: 'id', 
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_clients + '?filter=active_ind,eq,1&order=name'),
              update: (key, values) => sendRequest(URL_SERVICE_LOOKUP_ow_clients + '/' + encodeURIComponent(key), 'PUT', values),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_clients + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_appointment_status:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_appointment_status + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_appointment_status + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_work_types:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_work_types + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_work_types + "/" + encodeURIComponent(key)),
        }),

        LookupCustomDS_ow_view_technicians:  new CustomStore({
              key: 'id',
              loadMode: 'raw',
              load: () => sendRequest(URL_SERVICE_LOOKUP_ow_view_technicians + '?order=name'),
              byKey: (key) => sendRequestByKey(URL_SERVICE_LOOKUP_ow_view_technicians + "/" + encodeURIComponent(key)),
        }),

        CustomStoreMain: new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            load: () => sendRequest(URL_SERVICE),
            insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
        }),
    });

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
              return fetch(url, {
                  method,
                  credentials: 'include',
              })
              .then(response => response.json())
              .then(json => {
                  return json.records;
              })
          }
          return fetch(url, {
              method,
              body: JSON.stringify(values),
              headers: {
                  'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              },
              credentials: 'include',
          }).then((result) => {
              if (result.ok) {
                  return result.text().then((text) => text && JSON.parse(text));
              }
              return result.json().then((json) => {
                  throw json.Message;
              });
          });
    };

    const sendRequestByKey = (url, method = 'GET') => {
        //console.log('sendRequestByKey called:', url, method);
        //console.trace(); // This will print the call stack to help trace where the call is coming from

        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        return fetch(url, {
                  method,
                  headers: {
                      'Accept': 'application/json',
                  },
                  credentials: 'include',
              })
              .then(response => response.json())
    };

    const saveData = async () => {
        //console.log('Save data');
        await state.CustomStoreMain.update(state.formData.id, state.formData)
        if (state.formData.recreate_appointments_ind) {
            //console.log('recreateAppointments for client', state.formData.client_id, state.formData.appointment_date)
            await state.LookupCustomDS_ow_clients.update(state.formData.client_id, {recreate_appointments_ind: 1, first_appointment_date: state.formData.appointment_date})
        }
    };

    const insertData = async () => {
        // console.log('Insert data');
         return await state.CustomStoreMain.insert(state.formData);
    };

    const clearForm = () => {
          state.formData = {};
    };

    var isGridValidated = true
    var initialCodeValue
    var isInsertMode = false

    onMounted(async () => {
        //if (props.appointment_id != null) state.formData = await this.state.CustomStoreMain.byKey(props.appointment_id)
    });

    return {
        saveData,
        insertData,
        clearForm,
        gridWidth,
        maxWidth,
        state,
        isGridValidated,
        initialCodeValue,
        isInsertMode
    };
  },
  methods: {
      
      async loadFormData(id) {
          //
          // nf 28/6/2023 
          // init disposal value in order to invoke onchange event which disables dispose field
          //
          // this.$refs.formMaster.instance.getEditor('device_disposal_ind').option('value', true)

          //console.log('loadFormData')
          this.state.formData = {}
          if (id != undefined) {
                this.isInsertMode = false
                this.state.formData = await this.state.CustomStoreMain.byKey(id)
          } else {
                this.isInsertMode = true
                this.state.formData.confirmed_ind = 1
                this.state.formData.locked_ind = 0
                this.state.formData.work_completed_ind = 0
                this.state.formData.work_total_price_vat_ind = 0
                this.state.formData.vat_percent = 0
                this.state.formData.vat_value = 0.00
                this.state.formData.total_value = 0.00
                this.state.formData.collected_amount = 0.00
                this.state.formData.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ')
          }

          //this.initialCodeValue = this.state.formData.code
      },
              
      async onClientIDChange (e) {
            //console.log('onClientIDChange outter')
            if (e.event && (e.event.type === 'dxclick' || e.event.type === 'keydown')) {
                //console.log('onClientIDChange inner')
                // Handle the value change
                const modelrow = await this.state.LookupCustomDS_ow_clients.byKey(e.value)
                this.$refs.formMaster.instance.updateData('appointment_address', modelrow.address_street + ' ' + modelrow.address_city + ' ' + modelrow.address_postal_code)
                this.$refs.formMaster.instance.updateData('work_type_id', modelrow.work_type_id)
                this.$refs.formMaster.instance.updateData('work_total_price', modelrow.subscription_amount)
            }
      },
      onClickSave: async function() {
          const formValidator = this.dataForm.validate()
          this.isGridValidated = true
          let formSaved = false

          if (formValidator.isValid) {
              if (!this.isInsertMode) {
                  await this.saveData()
                  formSaved = true
              } else {
                  await this.insertData()
                  formSaved = true
              }
          }

          if (this.isGridValidated && formSaved) this.$emit('closePopupEvent', true)

      },
      onClickClose: function() {
          this.$emit('closePopupEvent', true)
      },

      onRowValidating(e) {
          this.isGridValidated = this.isGridValidated && e.isValid
      }

  },
  computed: {    
      dataForm: function() {
          return this.$refs.formMaster.instance
      },
  }
};
</script>

<style lang="scss">

/* Target the header titles in the DxDataGrid */
.dx-datagrid-headers .dx-datagrid-text-content {
  font-weight: bold;
}

/* Target the labels in the DxForm */
.dx-field-item-label-text {
  font-weight: bold;
}

.ow-list-filters {
    display: flex;
    background: #ffffff;
    padding: 0 8px 0 8px;
}

.dx-toolbar-label .dx-toolbar-item-content > div {
    width: 200px;
}

.filter-label {
    font-weight: bold;
    align-content: center;
    padding: 0 5px 0 5px;
}

</style>

<style scoped>


#form {
 margin-top: 25px;
}
.caption {
  font-size: 18px;
  font-weight: 500;
}
.option {
  margin-top: 10px;
}
.dx-fieldset-header {
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 4px;
}

</style>
