<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>

    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :title="title"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                        show: {
                            type: 'pop',
                            duration: 800,
                            from: {
                                scale: 0.90
                            }
                        }, 
                    }"
    >
        <DxScrollView height="100%" width="100%">
            <AppointmentForm
                ref="appointmentForm"
                :appointment_id="selectedAppointmentID" 
                @closePopupEvent="onClosePopupEmit">
            </AppointmentForm>
        </DxScrollView>

    </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="id"
      :data-source="CustomStoreMain"
      :columns="columns"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      :selection-filter="['appointments_frequency', '=', '3']"
      :scrolling="{ mode: 'standard', useNative: 'never', showScrollbar: 'never' }"

      @exporting="onExporting"
      @selection-changed="onSelectionChanged"
  >

      <DxPaging 
          :enabled="true"
          :page-size="5"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="false"/>

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="false"

          :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
            <DxItem location="after">
                <DxDateBox 
                    placeholder="Ημερομηνία"
                    :show-clear-button="true"
                    :use-mask-behavior="true"
                />
            </DxItem>

            <DxItem
                widget="dxButton"
                :options="{
                    text: 'Ενημέρωση Πιστώσεων',
                    onClick: updatePayments
                }"
            />

            <DxItem name="exportButton" 
                :options="{
                    hint: 'Εξαγωγή δεδομένων'
                }"
            />
      </DxToolbar>

      <DxColumn
          data-field="clients_sources_id"
          caption="Πηγή Πελάτη"
      >
            <DxLookup
                :data-source="CustomStoreLookup_ow_clients_sources"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>

      <DxColumn
          data-field="appointment_date"
          caption="Ημερομηνία & ώρα"
          :dataType="'date'"
      
          :format="{  type: 'datetime',
                      formatter: (value) => value.toLocaleString('el-GR', {
                          weekday: 'long', 
                          year: 'numeric', 
                          month: 'long', 
                          day: '2-digit',
                          hour: '2-digit', 
                          minute: '2-digit',
                          second: '2-digit' 
                      })
                    }"
      >
      </DxColumn>

      <DxColumn
          data-field="client_id"
          caption="Πελάτης"
          :hiding-priority="110"
      >
          <DxLookup
              :data-source="CustomStoreLookup_ow_clients" 
              value-expr="id"
              display-expr="name"
          />
      </DxColumn>

      <DxColumn
          data-field="client_address"
          caption="Διεύθυνση"
      >
      </DxColumn>

      <DxColumn
          data-field="work_total_price"
          caption="Κόστος Εργασίας"
          data-type="number"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="work_total_price_vat_ind"
          caption="Κόστος Εργασίας με ΦΠΑ"
          data-type="boolean"
          :visible="false"
      >
      </DxColumn>

      <DxColumn
          data-field="collected_amount"
          caption="Ποσό Είσπραξης"
          data-type="number"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="credit"
          caption="Πίστωση"
          data-type="number"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxColumn
          data-field="total_value"
          caption="Σύνολο Τιμολογίου"
          data-type="number"
          :format="formatCurrency"
      >
      </DxColumn>

      <DxSummary>
          <DxTotalItem
              column="collected_amount"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="credit"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="total_value"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />

      </DxSummary>

      <DxColumn 
            type="buttons">
                <DxButton 
                    text="Επεξεργασία"
                    icon="edit"
                    hint="Επεξεργασία"
                    :on-click="onCustomRowEdit"
                />
          >
      </DxColumn>

      <DxSelection
          mode="multiple"
      />

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>

</div>

</template>

<script setup>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      DxLookup, //DxRemoteOperations, //DxRequiredRule,
      DxToolbar,
      DxItem,
      DxButton,
      DxSummary,
      DxTotalItem,
      DxSelection
  } from 'devextreme-vue/data-grid'; 

  // import { DxButton as DxToolbarButton } from 'devextreme-vue/button';

  import 'whatwg-fetch'
  import CustomStore from 'devextreme/data/custom_store'
  import { confirm, alert } from 'devextreme/ui/dialog'
  //import { DxDateRangeBox } from 'devextreme-vue/date-range-box';
  //import DxDateBox from 'devextreme-vue/date-box';  
  
  import DxPopup from 'devextreme-vue/popup'
  import DxScrollView from 'devextreme-vue/scroll-view'
  import AppointmentForm from './ow-appointments-form.vue'
  
  //import DataSource from 'devextreme/data/data_source'

  import OParameters from '../es-globals'

  import { ref, /*reactive, computed, */ onMounted } from 'vue'

  //import { exportDataGrid } from 'devextreme/pdf_exporter';
  const dataGrid = ref(null)
  
  const title = 'Πιστώσεις'
  const pageSizes = [5, 10, 20, 0]
  
  const selectedAppointmentID = ref(null)
  const isPopupVisible = ref(false)
  const formatCurrency = { 
                    type: 'currency',
                    currency: 'EUR', 
                    useGrouping: true, 
                    precision: 2 
                }

  const URL_SERVICE = OParameters.Host.URI + 'ow_view_rep_credits'
  const URL_SERVICE_ow_clients = OParameters.Host.URI + 'ow_clients'
  //const URL_SERVICE_ow_users = OParameters.Host.URI + 'ow_users'
  const URL_SERVICE_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources'
  const URL_SERVICE_ow_appointments = OParameters.Host.URI + 'ow_appointments'
  
    const CustomStoreMain = new CustomStore({
        key: 'appointment_id',
        //loadMode: 'raw',
        //load: () => this.sendRequest(URL_SERVICE + '?filter=confirmed_ind,eq,' + this.confirmedInd + '&filter=app_year,eq,' + this.selectedYear + '&filter=app_month,eq,2100-' + this.selectedMonth.toString().padStart(2, '0') + '-01'),
        load: () => sendRequest(URL_SERVICE + '?filter=paid,eq,0'),
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    const CustomStoreMain_ow_appointments = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_appointments),
        insert: (values) => sendRequest(URL_SERVICE_ow_appointments, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE_ow_appointments + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE_ow_appointments + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE_ow_appointments + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_clients = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_clients_sources = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients_sources),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients_sources + "/" + encodeURIComponent(key)),
    })

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
            return fetch(url, {
                method,
                credentials: 'include',
            })
            .then(response => {
                return response.json().then(json => {
                    if (response.ok) return json.records
                    throw json.Message
                })
            })
        }
        return fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            credentials: 'include',
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => text && JSON.parse(text))
            }
            return result.json().then((json) => {
                throw json.Message
            })
        });

    }

    const onExporting = (e) => {
        OParameters.methods.exportDataGridToPdfExcel(e, title)
    }

    const onClosePopupEmit = () => {
        console.log('onClosePopupEmit')
        isPopupVisible.value = false
        dataGrid.value?.instance?.refresh()
    }

    const onCustomRowEdit = (e) => {
        console.log('onCustomRowEdit')
        selectedAppointmentID.value = e.row.key
        isPopupVisible.value = true
    }

    const onSelectionChanged = (e) => {
        console.log(e)
    }

    const updatePayments = () => {
        const dG = dataGrid.value?.instance
        if (!dG) return

        const selectedRowKeys = dG.getSelectedRowKeys()
        if (selectedRowKeys.length === 0) {
            alert('<b>Πρέπει να επιλέξετε τουλάχιστον μια γραμμή<b>', 'Προσοχή')
            return
        }

        let result = confirm("<b>Eνημέρωση επιλεγμένων πιστώσεων?</b>", "Επιβεβαίωση");

        result.then((dialogResult) => {
            
            if (dialogResult) {

                //console.log('dataGrid.value?.instance?.getSelectedRowKeys()')
                console.log(dataGrid.value?.instance?.getSelectedRowKeys())

                const selectedRowsData = dG.getSelectedRowsData()

        
                selectedRowsData.forEach(rowData => {
                    const work_total_price = Number(rowData.work_total_price) || 0
                    const vat_value = Number(rowData.vat_value) || 0
                    const work_total_price_vat_ind = Number(rowData.work_total_price_vat_ind) || 0

                    // Compute the new value for collected_amount based on the given SQL logic
                    const collected_amount = work_total_price_vat_ind === 1
                        ? work_total_price
                        : work_total_price + vat_value

                    CustomStoreMain_ow_appointments.update(rowData.appointment_id, { collected_amount: collected_amount }).then(() => {
                        console.log(`Record with key ${rowData.appointment_id} updated successfully.`)
                    }).catch(error => {
                        console.error('Error updating record ${rowData.appointment_id}:', error)
                    })
                    console.log('appointment_id: ', rowData.appointment_id, 'collected amount: ', collected_amount, 'work_total_price_vat_ind:', rowData.work_total_price_vat_ind, 'work_total_price:', rowData.work_total_price, 'vat_value:', rowData.vat_value)
                })

                dataGrid.value?.instance?.refresh()
            }
        })
    }

    // Lifecycle hooks
    onMounted(() => {
    })

</script>
<style lang="scss">
</style>
